import type { FieldProtocol, HighLightConditions, RoleValue } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useApplicationContext } from '../../contexts'
import type { AppRole } from '../../types'
import { CellHighlighter } from '../Highlighter'
import type { TooltipListItem } from '../TooltipList'
import { TooltipList, useWidth } from '../TooltipList'

const RoleListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean } & Pick<React.CSSProperties, 'justifyContent'>>`
    position: relative;
    max-width: 100%;
    display: flex;
    gap: 6px;
    justify-content: ${({ justifyContent }) => justifyContent};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    overflow: hidden;
`

const RoleItemWrapper = styled.div<{ enableOverFlow?: boolean; direction?: 'horizontal' | 'vertical' }>`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    /* background-color: var(--color-gray-200);
    border-radius: 4px; */
    padding: 0 6px;

    ${({ direction }) =>
        direction === 'vertical'
            ? css`
                  width: 22px;
                  margin-bottom: 6px;
              `
            : css`
                  height: 22px;
                  line-height: 22px;
              `}

    /* overflow: ${({ enableOverFlow }) => (enableOverFlow ? 'hidden' : 'unset')}; */
    /* overflow: hidden; */
    /* margin-bottom: 6px; */
∏
    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`

const RoleName = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

const RoleInfo = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`

const RoleDescribe = styled.span`
    margin-left: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    overflow: hidden;
    text-overflow: ellipsis;
`

interface RoleItemPreviewerProps {
    children?: React.ReactNode
    id: string
    role: AppRole
    isLast?: boolean
    direction?: 'horizontal' | 'vertical'
    isShowId?: boolean
}

interface RolePreviewerProps extends Pick<React.CSSProperties, 'justifyContent'> {
    field: FieldProtocol
    children?: React.ReactNode
    size?: 'default' | 'lg'
    data?: RoleValue
    width?: number
    isWrap?: boolean
    styleConditions?: HighLightConditions
    isShowId?: boolean
    direction?: 'horizontal' | 'vertical'
}

export const RoleItemPreviewer: React.FC<RoleItemPreviewerProps> = ({ id, role, isShowId, isLast, direction }) => {
    const { id: roleId, name } = role
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)

    return (
        <RoleItemWrapper key={roleId} enableOverFlow={isLast} ref={widthRef} direction={direction}>
            <RoleInfo>
                <RoleName>{name}</RoleName>
                {isShowId && <RoleDescribe>{roleId}</RoleDescribe>}
            </RoleInfo>
        </RoleItemWrapper>
    )
}

export const RoleValuePreviewer: React.FC<RolePreviewerProps> = ({
    field,
    data = [],
    width = 160,
    isWrap = true,
    justifyContent = 'flex-start',
    styleConditions,
    isShowId,
    direction
}) => {
    const { roleOptions } = useApplicationContext()
    const { id } = field
    const text = data.join(',')
    const list = useMemo(() => {
        return data.reduce<TooltipListItem[]>((list, id) => {
            const role = find(item => item.id === id, roleOptions)
            if (!role) {
                return list
            }
            list.push({
                label: role.name,
                value: role.id
            })
            return list
        }, [])
    }, [data, roleOptions])

    return (
        <>
            <CellHighlighter fieldId={id} type="person" value={text} conditions={styleConditions} />
            <TooltipList
                data={list.map((item, index) => ({ label: item.label, value: String(index) }))}
                width={width}
                key={data.join('-')}
                disabled={isWrap}
                render={visibleNum => {
                    const renderList = list.slice(0, visibleNum)
                    return (
                        <RoleListWrapper isWrap={isWrap} justifyContent={justifyContent} isPadding={visibleNum < data.length}>
                            {renderList.map((item, index) => {
                                const role = find(role => role.id === item.value, roleOptions)
                                if (!role) {
                                    return null
                                }
                                return (
                                    <RoleItemPreviewer
                                        id={String(index)}
                                        isShowId={isShowId}
                                        isLast={index === renderList.length - 1}
                                        direction={direction}
                                        key={id}
                                        role={role}
                                    />
                                )
                            })}
                        </RoleListWrapper>
                    )
                }}
            />
        </>
    )
}
