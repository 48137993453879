import type { DTFile, FieldProtocol, FileValue } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../../contexts'
import { FileUploader } from '../../../FileUploader'
import type { FieldBaseProps } from '../../types'

interface FileFieldProps extends FieldBaseProps {
    value?: FileValue
    fileField?: FieldProtocol
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    /* padding: 0 8px; */
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

const FileField: React.FunctionComponent<FileFieldProps> = props => {
    const { readOnly, onCellChange, value, config, fileField } = props
    const { uploadyOptions, videoUploadyOption } = config
    const { isBuilder } = useApplicationContext()
    const enableItemActions = useMemo(
        () => ({
            canDelete: !readOnly,
            canDownload: fileField?.type !== 'video',
            canPreview: true,
            canRename: !readOnly
        }),
        [fileField?.type, readOnly]
    )

    const handleChange = useCallback(
        (files: DTFile[]) => {
            onCellChange?.({ type: 'file', value: files })
        },
        [onCellChange]
    )

    return (
        <SCxContainer>
            {uploadyOptions && videoUploadyOption && (
                <FileUploader
                    isPexels={isBuilder}
                    withoutPopper
                    autoOpenWhenEmpty={false}
                    uploadyOptions={fileField?.type === 'video' ? videoUploadyOption : uploadyOptions}
                    enableItemActions={enableItemActions}
                    disableUpload={readOnly}
                    onChange={handleChange}
                    uploadFileType={fileField?.type === 'video' ? 'video' : 'file'}
                    value={value}
                />
            )}
        </SCxContainer>
    )
}

export default FileField
