import type { CascadeOption, CascadeValue } from '@byecode/ui'
import { CascadeList, IconFont, Popover, Text, usePopoverHeight } from '@byecode/ui'
import type { CascadeConfig } from '@lighthouse/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncRetry, useToggle, useUpdateEffect } from 'react-use'

import { popoverMaxHeight } from '../../constant'
import { SelectPlaceHolder } from '../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../types'
import * as SC from './styles'

interface DepartmentFieldProps extends FieldBaseProps {
    value: string[]
    cascadeConfig: CascadeConfig
}

const DepartmentField: React.FunctionComponent<DepartmentFieldProps> = props => {
    const {
        onCellChange,
        value: data,
        isControlled,
        config: { placeholder },
        readOnly,
        cascadeConfig,
        onSaveCellChange,
        onFetchCascadeOptions
    } = props
    const { cascade } = cascadeConfig ?? {}
    const {
        cascadeFieldPointer = '',
        cascadePointer = '=',
        showPath,
        isLastLevel,
        filter,
        parentFieldPointer = '',
        sortFieldPointer = '',
        cascadeShowFieldPointer = ''
    } = cascade ?? {}

    const [value, setValue] = useState(data)
    const [opened, setOpened] = useToggle(false)

    const { value: options, retry } = useAsyncRetry(async () => {
        const data = await onFetchCascadeOptions?.({
            fieldPointer: 'ID',
            showFieldPointer: 'DEPARTMENT_NAME',
            dsId: 'DEPARTMENT_DATASOURCE',
            sortFieldPointer,
            filter,
            parentFieldPointer: 'PARENT_DEPARTMENT'
        })
        return data ?? []
    }, [cascadeFieldPointer, cascadePointer, sortFieldPointer, filter, parentFieldPointer, cascadeShowFieldPointer, onFetchCascadeOptions])

    const { t } = useTranslation()

    useUpdateEffect(() => {
        setValue(data)
    }, [data])

    const { ref, height: maxHeight } = usePopoverHeight(opened, popoverMaxHeight)

    const flatOptions = useMemo(() => {
        function getChildOption(option: CascadeOption, list: CascadeOption[]) {
            const { value, label, path, labelPath, children = [] } = option
            let arrList = list
            for (const child of children) {
                const newChild = {
                    ...child,
                    path: `${path}/${child.value}`,
                    labelPath: `${labelPath}/${child.label}`,
                    isLast: (child.children ?? []).length === 0
                }
                arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
            }
            return arrList
        }
        return (
            options?.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, []) ?? []
        )
    }, [options])

    const tagList = useMemo(() => value.map(v => flatOptions.find(option => option.value === v)), [flatOptions, value])

    const handleChange = useCallback(
        (v: CascadeValue) => {
            setValue(v)
            onCellChange?.({ type: 'cascade', value: v })
        },
        [onCellChange]
    )

    const handleClear = useCallback(
        (id?: string) => {
            const newValue = id ? value.filter(v => v !== id) : []
            setValue(newValue)
            onCellChange?.({ type: 'cascade', value: newValue })
        },
        [onCellChange, value]
    )

    return (
        <Popover opened={opened} zIndex={200} position="bottom-start" onChange={setOpened} withinPortal width="target" disabled={readOnly}>
            <Popover.Target>
                <SC.Container
                    type="button"
                    data-field-border={opened}
                    onClick={() => {
                        setOpened(true)
                    }}
                >
                    <SC.Wrapper ref={ref}>
                        <SC.TagWrapper>
                            {tagList.map(v => (
                                <SC.Tag key={v?.value}>
                                    <SC.TagLabel>{showPath ? v?.labelPath : v?.label}</SC.TagLabel>
                                    <IconFont
                                        type="Close"
                                        color="var(--color-gray-400)"
                                        size={16}
                                        onClick={e => {
                                            e.stopPropagation()
                                            v?.value && handleClear(v?.value)
                                        }}
                                    />
                                </SC.Tag>
                            ))}
                            {tagList.length === 0 && <SelectPlaceHolder label={placeholder} />}
                        </SC.TagWrapper>
                    </SC.Wrapper>
                    {!readOnly && (
                        <SC.IconWrapper>
                            {tagList.length > 0 && (
                                <IconFont
                                    type="CloseCircle"
                                    color="var(--color-gray-400)"
                                    size={16}
                                    onClick={e => {
                                        e.stopPropagation()
                                        handleClear()
                                    }}
                                />
                            )}
                            <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />
                        </SC.IconWrapper>
                    )}
                </SC.Container>
            </Popover.Target>
            <Popover.Dropdown compact>
                <CascadeList
                    data={value}
                    onChange={handleChange}
                    // onPathChange={onPathChange}
                    lastLevel={isLastLevel}
                    searchable
                    emptyProps={{
                        description: t('noFindData')
                    }}
                    multiple
                    searchPlaceholder={t('search')}
                    options={options}
                    style={{ maxHeight }}
                    styles={{
                        columns: {
                            minHeight: 200
                        },
                        search: {
                            '&:focus-within': {
                                borderColor: 'var(--color-app-main)'
                            }
                        }
                    }}
                />
            </Popover.Dropdown>
        </Popover>
    )
}

export default DepartmentField
