import { IconFont, Loading, Message } from '@byecode/ui'
import type { Brand, HttpResp } from '@lighthouse/core'
import React, { useMemo, useState } from 'react'
import { useAsyncFn, useUpdateEffect } from 'react-use'

// import useSWRMutation from 'swr/mutation'
import type { AppImportUser } from '../../types'
import { getBrand } from '../../utils'
import { Finish } from './Finish'
import * as SC from './styles'
import { Upload } from './Upload'

interface UserImportProps {
    onImportAppUser: (arg: File) => Promise<HttpResp<AppImportUser>>
    onOpenSpaceGrade?: (defaultTab?: 'version_upgrade' | 'sms_purchase') => void
    onOpenSaleSpaceGrade?: () => void
    onClose: () => void
}

export const UserImport: React.FC<UserImportProps> = ({
    onImportAppUser,
    onOpenSpaceGrade,
    onOpenSaleSpaceGrade,
    onClose
}) => {
    const [importData, setImportData] = useState<AppImportUser>()
    const [step, setStep] = useState(0)

    const [{ loading, value }, run] = useAsyncFn<(arg: File) => Promise<HttpResp<AppImportUser>>>(arg => onImportAppUser(arg), [])

    const recordLimitError = useMemo(
        () => (
            <>
                记录数达到上限
                <SC.UpgradeVersion
                    onClick={e => {
                        e.stopPropagation()
                        const brand = getBrand()
                        brand === 'I_CLICK' ? onOpenSaleSpaceGrade?.() : onOpenSpaceGrade?.('version_upgrade')
                    }}
                >
                    请升级版本
                </SC.UpgradeVersion>
            </>
        ),
        [onOpenSaleSpaceGrade, onOpenSpaceGrade]
    )

    useUpdateEffect(() => {
        if (!value) {
            return
        }
        if (value.code === '0000000') {
            const { content } = value
            const { failCount, successCount } = content
            Message.success({
                title: '导入完成',
                description: `导入完成${successCount}人, 导入失败${failCount}人`
            })
            setImportData(content)
            setStep(1)
            return
        }
        Message.error({
            title: '导入失败',
            description: value.code === '0011025' ? recordLimitError : '模版格式错误'
        })
    }, [value])


    const content = useMemo(() => {
        if (loading) {
            return <Loading shape="indicator" description="正在导入" />
        }
        if (step) {
            return <Finish data={importData} onClose={onClose} />
        }
        return <Upload onImport={run} onClose={onClose} />
    }, [loading, step, run, onClose, importData])

    return (
        <SC.PopoverContainer>
            <SC.Close onClick={onClose}>
                <IconFont type="Close" size={20} fill="var(--color-gray-500)" />
            </SC.Close>
            {content}
        </SC.PopoverContainer>
    )
}
