import type { AppUser } from '@lighthouse/core'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

import type { AppDepartment, AppRole } from '../types'

export type ApplicationContextType = {
    personOptions: AppUser[]
    roleOptions: AppRole[]
    departmentOptions: AppDepartment[]
    pageTarget?: string
    isRealityMobile?: boolean
    isBuilder: boolean
    /** 获取阿里云播放凭证 */
    fetchVideoPlayAuth?: (videoId: string) => Promise<string>

    /** 是否有更新 */
    hasUpdate?: boolean
    mutateHasUpdate?: (v?: boolean) => void
}

export const ApplicationContext = createContext<ApplicationContextType>({
    personOptions: [],
    roleOptions: [],
    departmentOptions: [],
    isRealityMobile: false,
    isBuilder: false,
    hasUpdate: false,
    mutateHasUpdate: () => void 0
})

type ApplicationProviderProps = PropsWithChildren<{
    value: ApplicationContextType
}>

export const ApplicationProvider: FC<ApplicationProviderProps> = props => <ApplicationContext.Provider {...props} />

export const useApplicationContext = () => useContext(ApplicationContext)
