import type { I18TranslationKey } from '../types'

export const en: Record<I18TranslationKey, string> = {
    bindMobile: 'Bind mobile number',
    bindEmail: 'Bind email',
    firstLoginNeedAccount: 'First WeChat login requires binding {{account}}',
    mobileCode: 'Mobile Number',
    noVerificationCodeReceived: 'Did not receive the verification code?',
    noNeedLogin: 'Login temporarily unsupported',
    registerAccount: 'Register Account',
    mobileLogin: 'Mobile Number Login',
    emailLogin: 'Email Login',
    wechatLogin: 'WeChat Login',
    emailUrl: 'Email Address',
    noAccount: "Don't have an account yet?",
    next: 'Next',
    goLogin: 'Go to Login',
    alreadyHaveAnAccount: 'Already have an account?',
    selectRegisterWay: 'Choose registration method',
    perfectInfo: 'Complete Information',
    verificationCode: 'Verification Code',
    sure: 'Confirm',
    yourRole: 'Your Role',
    emailFormatIsIllegal: 'Invalid email format',
    mobilePhoneNumberFormatIsIllegal: 'Invalid phone number format',
    mobileRegister: 'Phone',
    emailRegister: 'Email',
    email: 'Email',
    shortMessage: 'SMS',
    pleaseInputYourEmail: 'Please enter email',
    pleaseInputYourMobile: 'Please enter a valid email',
    pleaseInputYourValidEmail: 'Please enter phone number',
    pleaseInputYourValidMobile: 'Please enter a valid phone number',
    getVerificationCode: 'Get Verification Code',
    register: 'Register',
    pleaseFillOut: 'Please fill out',
    notEmpty: 'Can not be empty',
    getAgain: 'Resend',
    loginSuccess: 'login successful',
    confirmMergeAccounts: `Confirm to merge accounts?`,
    merge: 'merge',
    iAwareRisks: 'I am aware of the risks',
    cancel: 'cancel',
    changeBindMobile: 'Change mobile phone number',
    mergeAccount: 'Merge accounts',
    keepMobileAndDeleteWechat: 'Keep the mobile phone number account Delete the WeChat account',
    keepWechatAndDeleteMobile: 'Keep WeChat account Delete mobile phone account',
    youAccountUnderReview: 'Your account is under review',
    backLoginPage: 'Return to login page',
    MergeWeChatAccountDescription:
        'To merge the accounts registered with WeChat and mobile phone numbers into one account, please select the account to keep:',
    deleteWechatAccountWarn: 'This operation will delete your WeChat account and cannot be restored, so please operate with caution!',
    mobileAlreadyRegister: 'Mobile phone number has been registered',
    mobileAlreadyRegisterDesc:
        'The mobile phone number has been registered as: {{name}} ({{userId}}). If you still want to bind the mobile phone number, please click "Merge Accounts".',
    goHome: 'Return to Homepage',
    resendTime: `Resend after {{v}} s`,
    verificationMessage: `We have sent a verification code to {{v}}, please check your email`,
    verificationSMS: `We have sent a SMS verification code to {{v}}`,
    inputVerificationCode: `Enter {{v}} verification code`,
    search: 'Search',
    personNameOrPersonId: 'Personnel name, personnel number',
    noFindData: 'No data',
    noFindPerson: 'No user data',
    selected: 'Selected',
    pleaseWeChatLoginInWeChatBrowser: 'Please log in with WeChat in the WeChat browser.',
    refresh: 'Refresh',
    expiredPleaseRefresh: 'Expired, please refresh.',
    scanWechatLogin: 'Scan the QR code with WeChat to follow the official account to log in.',
    accountSetting: 'Account Settings',
    logout: 'Logout',
    notBind: 'Unable to Unbind',
    mobileNumber: 'Mobile Number',
    wechat: 'WeChat',
    removeBindAccountDescribe:
        '{{account}} is the only login method for this account. Please first bind another account as an alternative login method.',
    knew: 'Understood',
    continueUnbinding: 'Continue Unbinding',
    emailAccount: 'Email Account',
    notEnabledAccountLogin: '{{account}} login function is not enabled',
    alreadyBind: 'Already Bound',
    wechatAccount: 'WeChat Account',
    bindAfterUsedAccountLogin: 'After binding, you can log in using {{account}}',
    bindAccountAfterAccountNotEnable:
        'This {{account}} is already registered. Please change your {{account}}. If you still wish to bind, please first disassociate the previously failed {{account}} from its original account before binding it to the currently logged-in account.',
    bindMeetQuestion: 'Encountered a problem during binding',
    changeAccount: 'Change {{account}}',
    goBind: 'Go to Bind',
    unbind: 'Unbind',
    notEnable: 'Not Enabled',
    bindMobileNumber: 'Bind Mobile Number',
    bindMobileNumberAfterLoginApp: 'After binding, you can log in to the app using your mobile number.',
    enterSmsCode: 'Enter SMS Verification Code',
    confirmUnbinding: 'Are you sure you want to continue with the unbinding?',
    unBindNotLoginConfirmDescribe:
        'After unbinding, you will no longer be able to log in via {{account}}. Are you sure you want to continue with the unbinding?',
    alreadyUnbindAccount: ' Mobile {{account}} binding has been unlinked',
    // date
    beforeYesterday: 'The day before yesterday',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    afterTomorrow: 'The day after tomorrow',

    '0000000': 'Success',
    '0011001': 'Incorrect username or password',
    '0011002': 'User not registered',
    '0011004': 'Application does not exist on client side',
    '0011005': 'Page has no access permission',
    '0011009': 'User does not exist',
    '0011010': 'Name duplicate',
    '0011011': 'Verification code has expired',
    '0011013': 'Unable to enable workflow, please check the configuration and try again',
    '0011017': 'Application has been taken offline',
    '0011018': 'Domain name already exists, please re-enter',
    '0011019': 'User already registered',
    '0011021': 'Your account is under review',
    '0011029': 'No permission for application',
    '0011023': 'Test account connection failed',
    '0011024': 'Number of applications reached the limit',
    '0011025': 'Number of records reached the limit',
    '0011027': 'Attachment capacity reached the limit',
    '0011030': 'Duplicate phone number',
    '0011031': 'Department name duplicate',
    '0011032': 'Number of users reached the limit',
    '0011033': 'Number of applications with bound custom domain reached the limit',
    '0011036': 'Export exceeds 500 records',
    '0011037': 'Export exceeds 50MB',
    '0011039': 'Account exception, please contact administrator',
    '0011042': 'WeChat official account unauthorized',
    '0011043': 'Duplicate email',
    '0011044': 'Phone number already bound',
    '0011045': 'payment exception',

    '0011048': 'SMS quota exceeded',
    '0011049': 'No records found',
    '0011050': '',
    '0011052': 'WeChat template message exception',
    '0011054': 'Environment has anomalies, merging is prohibited',
    '0011055': 'Modifications are prohibited in the production environment',
    '0011057':
        'The user of this application has already been bound by other applications and cannot be bound to other applications any more.',
    '0011058': 'Private template is forbidden to operate',
    '0011059': 'WeChat cannot be linked repeatedly',
    '0011060': 'The two environment data tables are inconsistent, unable to switch to the synchronized data table environment',
    /**
     * 动作流相关节点异常 start
     */
    '0011061': 'Email sending exception',
    '0011062': 'SMS sending exception',
    '0011063': 'DingTalk robot exception',
    '0011064': 'WeChat robot exception',
    '0011065': 'The order has expired, please create a new order',
    /**
     * 动作流相关节点异常 end
     */
    '0011066': 'Page does not exist',
    '0011068': 'UV out of limit',
    '0011069':
        'Application replication failed because the application contains tables synchronized from other applications or synchronized users.',
    '0011070': 'Application template does not exist',
    '0011073': "You can't delete a user by taking an action",
    '0020000': 'Page does not exist or page not public!',

    '0030000': 'Filter exception, no need to throw'
}
