import React, { useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { cellActiveKeydownHandler, getNeighborCellDom } from '../../utils'
import { isDateValue, isFileValue, isIdsValue, isNumberValue, isRichTextValue, isTextValue } from '../../utils/helper'
import { DateValueEditor } from './DateValueEditor'
import { DepartmentValueEditor } from './DepartmentValueEditor'
import { FileValueEditor } from './FileValueEditor'
import { NotesValueEditor } from './NotesValueEditor'
import { NumberValueEditor } from './NumberValueEditor'
import { PersonValueEditor } from './PersonValueEditor'
import { RoleValueEditor } from './RoleValueEditor'
import { SelectValueEditor } from './SelectValueEditor'
import { SimpleTextValueEditor } from './SimpleTextValueEditor'
import * as SC from './styles'
import { TextValueEditor } from './TextValueEditor'
import type { TableCellEditorProps } from './types'
import { VideoValueEditor } from './VideoValueEditor'

export const FieldValueEditor: React.FC<TableCellEditorProps> = ({
    dataSourceInfo,
    recordId,
    data,
    width,
    field,
    editorHost,
    editable = true,
    enableFileDownload = false,
    focusOutLineColor = 'transparent',
    enableBackgroundColor = true,
    placeholder,
    autoFocus,
    shape,
    style,
    size,
    previewType,
    uploadOptions,
    videoUploadOptions,
    rootRef,
    scrollElement,
    onClose,
    onCellChange,
    onCellUpdate,
    richTextUploadOptions,
    isHiddenId
}) => {
    const handleCellActiveKeydown = useCallback(() => {
        const cellDataKey = 'data-key'
        const fieldDataKey = 'data-field'
        const cellFieldDataKey = 'data-cell-field'
        cellActiveKeydownHandler({
            sel: dom => {
                const currentDataKeyAttr = dom.getAttribute(cellDataKey)
                return currentDataKeyAttr
                    ? getNeighborCellDom({
                          containDom: rootRef?.current || null,
                          currentDataKey: currentDataKeyAttr,
                          direction: 'down',
                          cellDataKey
                      })
                    : null
            },
            cellDataKey,
            fieldDataKey,
            cellFieldDataKey,
            scrollElement,
            direction: 'down',
            enable: true
        })
    }, [rootRef, scrollElement])

    const [fieldWidth, fieldMinWidth] = useMemo(() => {
        const { type } = field
        switch (type) {
            case 'video':
            case 'file': {
                return [width, '300px']
            }
            case 'select': {
                return [width, '300px']
            }
            case 'date': {
                return [width, '280px']
            }
            case 'department': {
                return [width, '424px']
            }
            case 'role':
            case 'person': {
                return [width, '310px']
            }
            case 'number':
            case 'text':
            case 'notes':
            case 'url':
            case 'phoneNumber':
            case 'email': {
                return [width, undefined]
            }
            default: {
                return [width, undefined]
            }
        }
    }, [field, width])

    useHotkeys(
        'Escape, Enter',
        ev => {
            ev.stopPropagation()
            if (ev.key === 'Enter') {
                handleCellActiveKeydown()
            }
            onClose?.(true)
        },
        {
            enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']
        },
        [onClose]
    )

    const valueContent = useMemo(() => {
        const { type, id } = field
        switch (type) {
            case 'url':
            case 'phoneNumber':
            case 'email': {
                return (
                    <SimpleTextValueEditor
                        recordId={recordId}
                        field={field}
                        data={isTextValue(data) ? data : ''}
                        onCellChange={onCellChange}
                        focusOutLineColor={focusOutLineColor}
                        autoFocus={autoFocus}
                        enableBackgroundColor={enableBackgroundColor}
                        style={style}
                        size={size}
                        placeholder={placeholder}
                        editable={editable}
                    />
                )
            }
            case 'text': {
                return (
                    <TextValueEditor
                        recordId={recordId}
                        field={field}
                        data={isTextValue(data) ? data : ''}
                        focusOutLineColor={focusOutLineColor}
                        autoFocus={autoFocus}
                        onCellChange={onCellChange}
                        editable={editable}
                        placeholder={placeholder}
                        enableBackgroundColor={enableBackgroundColor}
                        style={style}
                        size={size}
                    />
                )
            }
            case 'select': {
                return (
                    <SelectValueEditor
                        field={field}
                        autoFocus={autoFocus}
                        recordId={recordId}
                        data={isIdsValue(data) ? data : []}
                        onCellChange={onCellChange}
                        onClose={onClose}
                    />
                )
            }
            case 'person': {
                return (
                    <PersonValueEditor
                        field={field}
                        recordId={recordId}
                        data={isIdsValue(data) ? data : []}
                        autoFocus={autoFocus}
                        isHiddenId={isHiddenId}
                        onCellChange={onCellChange}
                        onClose={onClose}
                    />
                )
            }
            case 'role': {
                return (
                    <RoleValueEditor
                        field={field}
                        recordId={recordId}
                        data={isIdsValue(data) ? data : []}
                        autoFocus={autoFocus}
                        isHiddenId={isHiddenId}
                        onCellChange={onCellChange}
                        onClose={onClose}
                    />
                )
            }
            case 'department': {
                return (
                    <DepartmentValueEditor
                        field={field}
                        recordId={recordId}
                        data={isIdsValue(data) ? data : []}
                        autoFocus={autoFocus}
                        isHiddenId={isHiddenId}
                        onCellChange={onCellChange}
                        onClose={onClose}
                    />
                )
            }
            case 'date': {
                return (
                    <DateValueEditor
                        // style={{ width: isMobile ? '100%' : width }}
                        width={width}
                        recordId={recordId}
                        field={field}
                        data={isDateValue(data) ? data : undefined}
                        onCellChange={onCellChange}
                        onClose={onClose}
                    />
                )
            }
            case 'number': {
                return (
                    <NumberValueEditor
                        key={`${recordId}${id}`}
                        recordId={recordId}
                        field={field}
                        shape={shape}
                        data={isNumberValue(data) ? data : undefined}
                        focusOutLineColor={focusOutLineColor}
                        enableBackgroundColor={enableBackgroundColor}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        onCellChange={onCellChange}
                    />
                )
            }
            case 'file': {
                return (
                    <FileValueEditor
                        uploadyOptions={uploadOptions}
                        recordId={recordId}
                        field={field}
                        enableFileDownload={enableFileDownload}
                        data={isFileValue(data) ? data : []}
                        onCellChange={onCellChange}
                    />
                )
            }
            case 'video': {
                return (
                    <VideoValueEditor
                        uploadyOptions={videoUploadOptions}
                        recordId={recordId}
                        field={field}
                        enableFileDownload={enableFileDownload}
                        data={isFileValue(data) ? data : []}
                        onCellChange={onCellChange}
                    />
                )
            }
            case 'notes': {
                return (
                    <NotesValueEditor
                        editorHost={editorHost}
                        dataSourceInfo={dataSourceInfo}
                        readonly={!editable}
                        recordId={recordId}
                        field={field}
                        data={isRichTextValue(data) ? data : undefined}
                        onCellChange={onCellChange}
                        onCellUpdate={onCellUpdate}
                        onCellActiveKeydown={handleCellActiveKeydown}
                        onClose={onClose}
                        richTextUploadOptions={richTextUploadOptions}
                    />
                )
            }

            default: {
                return <div />
            }
        }
    }, [
        field,
        recordId,
        data,
        onCellChange,
        focusOutLineColor,
        autoFocus,
        enableBackgroundColor,
        style,
        size,
        placeholder,
        editable,
        onClose,
        isHiddenId,
        width,
        shape,
        uploadOptions,
        enableFileDownload,
        videoUploadOptions,
        editorHost,
        dataSourceInfo,
        onCellUpdate,
        handleCellActiveKeydown,
        richTextUploadOptions
    ])
    return (
        <SC.ValueEditorWrapper width={fieldWidth} minWidth={fieldMinWidth}>
            {valueContent}
        </SC.ValueEditorWrapper>
    )
}
