import type { FieldType, FilterInputMode, InclusionRelation, OperatorOptions, TypeInstanceMap } from '@lighthouse/core'
import { FilterWay } from '@lighthouse/core'

export const InnerTypeToFilterType: Record<TypeInstanceMap, FilterWay[]> = {
    TEXT: [FilterWay.textFilter, FilterWay.personFilter, FilterWay.selectFilter],
    NUMBER: [FilterWay.numberFilter],
    ARRAY: [FilterWay.textFilter, FilterWay.personFilter, FilterWay.selectFilter],
    DATE: [FilterWay.dateFilter],
    BOOL: [],
    RICHTEXT: [],
    NULL: []
}

export const textOperatorOptions: OperatorOptions[] = [
    {
        label: '等于',
        value: '='
    },
    {
        label: '不等于',
        value: '!='
    },
    {
        label: '包含',
        value: 'contains'
    },
    {
        label: '不包含',
        value: 'notContains'
    },
    {
        label: '开头为',
        value: 'startWith'
    },
    {
        label: '结尾为',
        value: 'endWith'
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const numberOperatorOptions: OperatorOptions[] = [
    {
        label: '=',
        value: '='
    },
    {
        label: '≠',
        value: '!='
    },
    {
        label: '>',
        value: '>'
    },
    {
        label: '≥',
        value: '>='
    },
    {
        label: '<',
        value: '<'
    },
    {
        label: '≤',
        value: '<='
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const dateOperatorOptions: OperatorOptions[] = [
    {
        label: '等于',
        value: '='
    },
    {
        label: '不等于',
        value: '!='
    },
    {
        label: '早于',
        value: '<'
    },
    {
        label: '晚于',
        value: '>'
    },
    {
        label: '早于等于',
        value: '<='
    },
    {
        label: '晚于等于',
        value: '>='
    },
    {
        label: '包含',
        value: 'contains'
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const arrayOperatorOptions: OperatorOptions[] = [
    {
        label: '等于',
        value: '='
    },
    {
        label: '不等于',
        value: '!='
    },
    {
        label: '包含',
        value: 'contains'
    },
    {
        label: '不包含',
        value: 'notContains'
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const boolOperatorOptions: OperatorOptions[] = [
    {
        label: '是',
        value: '='
    },
    {
        label: '不是',
        value: '!='
    }
]

export const richTextOperatorOptions: OperatorOptions[] = [
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const fileOperatorOptions: OperatorOptions[] = [
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const propertyFilter: Record<TypeInstanceMap, OperatorOptions[]> = {
    TEXT: textOperatorOptions,
    NUMBER: numberOperatorOptions,
    DATE: dateOperatorOptions,
    BOOL: boolOperatorOptions,
    ARRAY: arrayOperatorOptions,
    RICHTEXT: richTextOperatorOptions,
    NULL: []
}

// export const lookupFilterOperator: Record<TypeInstanceMap, OperatorOptions[]> = {
//     TEXT: textOperatorOptions,
//     NUMBER: numberOperatorOptions,
//     DATE: dateOperatorOptions,
//     BOOL: boolOperatorOptions,
//     ARRAY: arrayOperatorOptions,
//     NULL: []
// }

export const customInputFieldType = ['id', 'text', 'number', 'email', 'phoneNumber', 'url', 'formula', 'join']

export const propertyMode: Record<FieldType, FilterInputMode[] | []> = {
    id: ['input', 'input', 'input', ''],
    textGeneration: ['input', 'input', 'input', ''],
    textGenerationByAudio: ['input', 'input', 'input', ''],
    textGenerationByImage: ['input', 'input', 'input', ''],
    booleanGenerationByText: ['checkbox', 'checkbox', 'input', ''],
    selectGenerationByText: ['select', 'multiSelect', 'select', ''],
    dateGenerationByText: ['date', 'date', 'rangeDate', ''],
    numberGenerationByText: ['input', 'input', 'input', ''],
    textGenerationByFile: ['input', 'input', 'input', ''],
    text: ['input', 'input', 'input', ''],
    number: ['input', 'input', 'input', ''],
    email: ['input', 'input', 'input', ''],
    phoneNumber: ['input', 'input', 'input', ''],
    url: ['input', 'input', 'input', ''],
    select: ['select', 'multiSelect', 'select', ''],
    date: ['date', 'date', 'rangeDate', ''],
    file: ['select', 'multiSelect', 'select', ''],
    video: ['select', 'multiSelect', 'select', ''],
    person: ['person', 'multiPerson', 'person', ''],
    formula: ['input', 'input', 'input', ''],
    notes: ['input', 'input', 'input', ''],
    role: ['select', 'multiSelect', 'select', ''],
    checkbox: ['checkbox', 'checkbox', 'input', ''],
    department: ['select', 'multiSelect', 'select', ''],
    aggregation: []
}

export const filterModeIndex: Record<string, number> = {
    '<': 0,
    '<=': 0,
    '=': 1,
    '>': 0,
    '>=': 0,
    '!=': 1,
    contains: 1,
    notContains: 1,
    isEmpty: 3,
    isNotEmpty: 3,
    startWith: 1,
    endWith: 1,
    between: 2,
    filterController: 4
}

export const symbol: InclusionRelation[] = [
    '<=',
    '<',
    '=',
    '>=',
    '>',
    '!=',
    'contains',
    'notContains',
    'isEmpty',
    'isNotEmpty',
    'startWith',
    'endWith',
    'between'
]

export const filterOperatorText: Record<TypeInstanceMap, Record<string, string>> = {
    TEXT: {
        '=': '',
        '!=': '不等于',
        contains: '',
        notContains: '不包含',
        startWith: '开头为',
        endWith: '结尾为',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },

    BOOL: {
        '=': '',
        contains: '包含'
    },
    ARRAY: {
        '=': '等于',
        '!=': '不等于',
        contains: '',
        notContains: '不包含',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    DATE: {
        '=': '',
        '>': '晚于',
        '<': '早于',
        '>=': '晚于等于',
        '<=': '早于等于',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    NUMBER: {
        '=': '=',
        '!=': '≠',
        '>': '>',
        '<': '<',
        '>=': '≥',
        '<=': '≤',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    RICHTEXT: {
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    NULL: {}
}

export const filterTileOperator: Record<TypeInstanceMap, string> = {
    TEXT: 'contains',
    NUMBER: 'contains',
    DATE: '=',
    BOOL: '=',
    ARRAY: 'contains',
    RICHTEXT: 'isNotEmpty',
    NULL: ''
}

export const visibleFilterOperatorText: Record<TypeInstanceMap, Record<string, string>> = {
    TEXT: {
        '=': '是',
        '!=': '不是',
        contains: '包含',
        notContains: '不包含',
        startWith: '开头为',
        endWith: '结尾为',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    BOOL: {
        '=': '是',
        contains: '包含'
    },
    ARRAY: {
        '=': '是',
        '!=': '不是',
        contains: '包含',
        notContains: '不包含',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    NUMBER: {
        '=': '=',
        '!=': '≠',
        '>': '>',
        '<': '<',
        '>=': '≥',
        '<=': '≤',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    DATE: {
        '=': '等于',
        '>': '晚于',
        '<': '早于',
        '>=': '晚于等于',
        '<=': '早于等于',
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    RICHTEXT: {
        isEmpty: '为空',
        isNotEmpty: '不为空'
    },
    NULL: {}
}
