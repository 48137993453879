import type { DataSourceAbstract, FieldADTValue, RelativeSelectConfig, TableColumns, ViewField } from '@lighthouse/core'
import { dropRepeats, find, reduce } from 'rambda'
import { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { CURRENT_USER, USER_DATASOURCE } from '../../../../constants'
import { useApplicationContext } from '../../../../contexts'
import { fieldConvertValue, getViewColumns } from '../../../../utils'
import type { FieldBaseProps } from '../../types'
import { getConvertValue } from './help'

interface RelativeDataControllerProps extends FieldBaseProps {
    value?: string
    relativeSelectConfig: RelativeSelectConfig
}

export const useRelativeDataController = function (props: RelativeDataControllerProps) {
    const { onCellChange, value, isControlled, relativeDataSource, relativeSelectConfig } = props

    const { dataSourceList, records = [], userRecord, options = [] } = relativeDataSource ?? {}
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const { relativeSelect } = relativeSelectConfig

    const { relativePointer = '', relativeFieldPointer = '', viewFieldSettings = [], filter, sorts, showMode } = relativeSelect ?? {}

    const relativeDataSourceData = useMemo(
        () => find(dataSource => dataSource.id === relativePointer, dataSourceList || []),
        [dataSourceList, relativePointer]
    )

    const currentRecords = useMemo(() => {
        if (userRecord) {
            return [...records, userRecord].filter(record => record.dsId === relativePointer || record.dsId === USER_DATASOURCE)
        }
        return records.filter(record => record.dsId === relativePointer || record.dsId === USER_DATASOURCE)
    }, [records, relativePointer, userRecord])

    const { viewOptions, schema } = relativeDataSourceData ?? {}

    const { tableProps = [] } = viewOptions ?? {}

    const valueRecordIds: string[] = useMemo(() => {
        return (value ?? '')
            .toString()
            .split(',')
            .reduce<string[]>((preVal, label) => {
                //  当前绑定非用户表 或 绑定用户表 但配置的选项值与当前用户变量的字段不同，才存在
                if (label.includes(CURRENT_USER.userId)) {
                    const userFieldPointer = label.replace(`${CURRENT_USER.userId}-`, '')
                    if (relativePointer === USER_DATASOURCE && userFieldPointer === relativeFieldPointer) {
                        return userRecord ? [...preVal, userRecord.id] : preVal
                    }
                    return userRecord ? [...preVal, label] : preVal
                }
                const record = find(record => {
                    const fieldValue = record.content[relativeFieldPointer]?.value?.toString() ?? ''
                    const field = schema?.[relativeFieldPointer]
                    if (!fieldValue || !field) {
                        return false
                    }
                    const newLabel = fieldConvertValue({ ...field, value: label } as FieldADTValue, {
                        personOptions,
                        isReallyNumber: true
                    }).toString()
                    return (
                        fieldConvertValue({ ...field, value: fieldValue } as FieldADTValue, {
                            personOptions,
                            roleOptions,
                            departmentOptions,
                            isReallyNumber: true
                        }).toString() === newLabel
                    )
                }, currentRecords ?? [])
                return record ? [...preVal, record.id] : preVal
            }, [])
    }, [currentRecords, departmentOptions, personOptions, relativeFieldPointer, relativePointer, roleOptions, schema, userRecord, value])

    const [data, setData] = useState<string[]>(valueRecordIds ?? [])

    const arrayValue = useMemo(
        () =>
            value?.split(',').reduce<string[]>((prev, current) => {
                const option = options.find(option => option.value.toString() === current.toString())
                if (option) {
                    return [...prev, option.id]
                }
                return prev
            }, []) ?? [],
        [options, value]
    )

    const filterViewSetting: TableColumns = useMemo(() => {
        if (!schema) {
            return tableProps
        }
        const currentColumns = getViewColumns({
            tableProps,
            value: viewFieldSettings,
            schema
        })
        return reduce<ViewField, TableColumns>(
            (preVal, curVal) => {
                const isExit = schema[curVal.fieldId]
                const fieldProp = find(item => item.id === curVal.fieldId, tableProps ?? [])
                if (!isExit || !fieldProp) {
                    return preVal
                }
                return [...preVal, { ...fieldProp, visible: curVal?.visible ?? true }]
            },
            [],
            currentColumns
        )
    }, [schema, tableProps, viewFieldSettings])

    const newRelativeDataSource: DataSourceAbstract | null = useMemo(
        () =>
            relativeDataSourceData && viewOptions
                ? { ...relativeDataSourceData, viewOptions: { ...viewOptions, sorts, filter, tableProps: filterViewSetting } }
                : null,
        [filter, filterViewSetting, relativeDataSourceData, sorts, viewOptions]
    )
    const handleRecordChange = useCallback(
        (val: string[]) => {
            const newValue = dropRepeats(val)
            if (!schema) {
                return
            }
            const saveValue = newValue.reduce<string[]>((preVal, recordId) => {
                if (recordId.includes(CURRENT_USER.userId)) {
                    return [...preVal, recordId]
                }
                const cellValue = getConvertValue({
                    recordId,
                    fieldId: relativeFieldPointer,
                    records: currentRecords,
                    schema,
                    personOptions,
                    roleOptions,
                    departmentOptions,
                    isReallyNumber: true
                })

                if (!cellValue || preVal.includes(cellValue)) {
                    return preVal
                }
                return [...preVal, cellValue]
            }, [])
            setData(newValue)
            onCellChange?.({ type: 'relativeSelect', value: saveValue.join(',') })
        },
        [currentRecords, departmentOptions, onCellChange, personOptions, relativeFieldPointer, roleOptions, schema]
    )

    const handleChange = useCallback(
        (v: string[]) => {
            setData(v)
            const value = v.reduce<string[]>((prev, current) => {
                const option = options.find(option => option.id === current)
                if (option) {
                    return [...prev, option.value]
                }
                return prev
            }, [])
            onCellChange?.({ type: 'relativeSelect', value: value.join(',') })
        },
        [onCellChange, options]
    )

    useUpdateEffect(() => {
        if (showMode === 'input') {
            setData(valueRecordIds)
            return
        }
        setData(arrayValue)
    }, [valueRecordIds, arrayValue])

    return {
        onRecordChange: handleRecordChange,
        relativeDataSource: newRelativeDataSource,
        recordValue: data,
        records,
        value: arrayValue,
        onChange: handleChange,
        userRecord,
        options
    }
}
