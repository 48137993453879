import { AppUserStatus } from "@lighthouse/core"

export const defaultFormat = 'yyyy-MM-dd HH:mm'

export const userStatusOptions = [{
    label: '待审核',
    value: AppUserStatus.REVIEWED
}, {
    label: '正常',
    value: AppUserStatus.ACTIVATED
}, {
    label: '已注销',
    value: AppUserStatus.DEPART
}]
