import { Enum } from '@lighthouse/tools'
import { type } from 'rambda'
import type { MakeADT } from 'ts-adt/MakeADT'

import type {
    ButtonProtocols,
    DesignProtocol,
    EdgeValue,
    FlexAlign,
    MaxContainerWidthSize,
    RequiredProtocol,
    UserProtocol
} from '../protocols'
import type { IconDecorationProtocol } from '../protocols/icon'
import type { TextAdvanceOption } from './blocks'

// 跳转方式导航为链接时 值可为 1，2  1 为外链 2为系统页面
export enum ApplicationSettingJumpType {
    linkUrl = 1,
    page
}

//  值可为 1，2  1 主按钮  2 次按钮
export enum ApplicationSettingNameType {
    firstLevel = 1,
    secondLevel
}

//  值可为 1，0  0默认  1 自定义
export type NavigationLogoType = 0 | 1

export enum ApplicationType {
    website,
    wxApplet
}

export enum NavigationShowMode {
    horizontal = 1,
    verticalWide,
    verticalNarrow
}

export interface NavigationBaseItem {
    id: string
    jumpType: ApplicationSettingJumpType // 1 为链接 2为页面
    name?: string
    value: string
    icon?: string
    type?: ApplicationSettingNameType // 1 为主按钮 2为次按钮
}

export interface NavigationLogo {
    logoType: NavigationLogoType
    imgUrl: {
        square: string
        // rectangle: string
    }
    jumpType: ApplicationSettingJumpType
    value: string
}

export enum ApplicationSettingAuthenticationWay {
    public,
    private
}

export enum ApplicationSettingPerfectMode {
    'COMMON' = 'COMMON',
    'ROLE' = 'ROLE'
}

export enum ApplicationSettingThemeNavBarMode {
    'custom' = 'custom',
    'light' = 'light',
    'dark' = 'dark',
    'transparent' = 'transparent'
}

export type ApplicationPrivateTemplateDevice = 'mobile' | 'desktop' | 'all'

export type ApplicationPrivateTemplateInitDevice = 'mobile' | 'desktop'
export interface ApplicationPrivateTemplate {
    canPrivateTemplate: boolean
    device?: ApplicationPrivateTemplateDevice
    initDevice?: ApplicationPrivateTemplateInitDevice
}

export interface ApplicationSettingAuthenticationField extends RequiredProtocol {
    fieldId: string
    title: string
    placeHolder: string
}

export interface ApplicationSettingAuthenticationCommon {
    roleId?: string
    isShown?: true
    fields: ApplicationSettingAuthenticationField[]
}

export const SMS_TYPE = Enum('SYSTEM', 'SELF')
export type SmsType = Enum<typeof SMS_TYPE>

export const SMS_PLATFORM = Enum('ALIYUN')
export type SmsPlatForm = Enum<typeof SMS_PLATFORM>

export type SmsConfig = {
    isOpened: boolean
    smsType: SmsType
    smsPlatform?: SmsPlatForm
    smsAccount?: string
    smsSign?: string
    smsTemplate?: string
}

export interface ApplicationSettingAuthentication {
    logo?: IconDecorationProtocol
    name: string
    describe: string
    login: {
        phone: {
            isOpened: boolean
        }
        email: {
            isOpened: boolean
        }
        google: {
            isOpened: boolean
            accountId?: string
        }
        weChat: {
            isOpened: boolean
            // isOpenedH5Auth: boolean
            isOpenedH5QrcodeLogin: boolean
            isBindPhone: boolean
        }
        landPageId?: string
    }
    register: {
        allowRegister: boolean
        isReviewed: boolean
    }
    perfect: {
        title: string
        describe: string
        selectRoleTitle: string
        selectRoleDescribe: string
        isOpened: boolean
        mode: ApplicationSettingPerfectMode
        commonSetting: ApplicationSettingAuthenticationCommon
        roleSetting: ApplicationSettingAuthenticationCommon[]
    }
}
export interface ApplicationSettingLeftPanel {
    isShow: boolean
}

export type ApplicationSettingNavbarButton = {
    id: string
    parentId?: string
    child?: string[]
    isAccess?: boolean
} & ButtonProtocols

export interface ApplicationSettingNavbarLinkList {
    list: Record<string, ApplicationSettingNavbarButton>
    child: string[]
}
export interface ApplicationSettingNavbar extends Pick<DesignProtocol, 'padding' | 'radius'> {
    linkList: ApplicationSettingNavbarLinkList
    buttonList: ApplicationSettingNavbarButton[]
    logo: NavigationLogo
    isShow: boolean
    showMode: NavigationShowMode
    themeMode: ApplicationSettingThemeNavBarMode
    width: number
    height: number
    maxWidth: MaxContainerWidthSize
    backgroundPadding: number
    isSticky?: boolean
    backgroundBlur?: boolean
    leftPadding: number
    topPadding: number
    leftAndRightPadding: number
    topAndDownPadding: number
    backgroundOverlay?: boolean
    align: FlexAlign
}

export interface ApplicationSettingWebTag {
    logo: string
    title: string
    backgroundColor: string
}

export interface ApplicationSettingThemeColor {
    id: string
    label: string
    value: string
}

export interface ApplicationSettingTheme {
    // colors: string[]
    primaryPointer: string
    palettes: ApplicationSettingThemeColor[]
    textPresetList: TextAdvanceOption[]
}

export interface ApplicationSettingSearchEngine {
    /**
     * @deprecated isOpened
     */
    isOpened: boolean
    verificationInfo: string
    /**
     * @deprecated secret
     */
    secret: string
    /**
     * @deprecated usage
     */
    usage: number
    /**
     * @deprecated quota
     */
    quota: number
}
export interface ApplicationSettingSEO {
    baidu: ApplicationSettingSearchEngine
    google: ApplicationSettingSearchEngine
    bing: ApplicationSettingSearchEngine
}

export interface ApplicationSettingThemeTabBar {
    backgroundColor: string
    fontColor: string
    fontActiveColor: string
}

export interface ApplicationSettingDomain {
    isUsedCustomDomain: boolean
    domainPrefix: string
    customDomain?: string
}

export interface ApplicationSettingAdvertising {
    isHideLogo: boolean
}

export interface ApplicationSettingWebApp {
    isPublishApp: boolean
}

export interface ApplicationSettingLanguageItem {
    title: string
    lang: string
}
export interface ApplicationSettingLanguage {
    list: ApplicationSettingLanguageItem[]
}

export type ApplicationSettingSms = Omit<SmsConfig, 'isOpened'>
export interface ApplicationWebsiteSetting {
    authentication: ApplicationSettingAuthentication
    navbar: ApplicationSettingNavbar
    webTag: ApplicationSettingWebTag
    theme: ApplicationSettingTheme
    seo: ApplicationSettingSEO
    domain: ApplicationSettingDomain
    advertising: ApplicationSettingAdvertising
    language: ApplicationSettingLanguage
    webApp: ApplicationSettingWebApp
    sms: ApplicationSettingSms
    privateTemplate: ApplicationPrivateTemplate
}

export interface ApplicationWxAppletSettingTabBarItem {
    id: string
    title: string
    icon: {
        normal: string
        selected: string
    }
    url: string
}
export interface ApplicationWxAppletSettingTabBar {
    isShow: boolean
    list: ApplicationWxAppletSettingTabBarItem[]
}

export interface ApplicationWxAppletSetting {
    authentication: ApplicationSettingAuthentication
    navbar: ApplicationSettingNavbar
    theme: ApplicationSettingTheme
    navbarTitle: {
        title: string
    }
    tabBar: ApplicationWxAppletSettingTabBar
}

export type ApplicationTypeName = 'website' | 'wxApplet'
// export type ApplicationTypeIcon = 'Desktop' | 'MiniProgramIcon'

// export const ApplicationTypeIconMap = new Map<ApplicationType, ApplicationTypeIcon>([
//     [0, 'Desktop'],
//     [1, 'MiniProgramIcon']
// ])

export const ApplicationTypeMap = new Map<ApplicationType, ApplicationTypeName>([
    [0, 'website'],
    [1, 'wxApplet']
])
