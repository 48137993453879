import { IconFont } from '@byecode/ui'
import { type AppUser, type PersonConfig, type PersonField as PersonConfigField, type SelectField,AppUserStatus } from '@lighthouse/core'
import { filter, find, reduce } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useAsyncRetry } from 'react-use'

import { useApplicationContext } from '../../../../../contexts'
import { PersonDrawer } from '../../../../FieldDrawer'
import { TagItemPreviewer } from '../../../../PersonSelect/TagItemPreviewer'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../../types'
import * as CM from '../../commonStyles'
import * as SC from '../styles'

interface PersonDrawerFieldProps extends FieldBaseProps {
    value: string[]
    field?: SelectField | null
    personConfig?: PersonConfig
    personField?: PersonConfigField
}

export const PersonDrawerField: React.FunctionComponent<PersonDrawerFieldProps> = ({
    value: data,
    personConfig,
    isControlled,
    readOnly,
    config,
    personField,
    onFetchPersonOptions,
    onCellChange
}) => {
    const { placeholder, person: personInputConfig, title = '' } = personConfig ?? {}
    const { person } = personField ?? {}
    const { multiple } = person ?? {}
    const { filter: personFilter } = personInputConfig ?? {}
    const { personOptions, pageTarget } = useApplicationContext()

    const { value: filterPersonOptions } = useAsyncRetry(() => {
        if (!personFilter || (personFilter.expression?.conditions ?? []).length === 0 || !onFetchPersonOptions) {
            return Promise.resolve(undefined)
        }
        return onFetchPersonOptions?.(personFilter)
    }, [personFilter])

    const currentPersonOptions = useMemo(() => filterPersonOptions || personOptions, [filterPersonOptions, personOptions])

    const [value, setValue] = useState(data ?? [])

    const [opened, setOpened] = useState(false)

    const usedValue = useMemo(() => (isControlled ? data : value), [data, isControlled, value])

    const currentTags = useMemo(() => {
        return reduce<string, AppUser[]>(
            (preVal, userId) => {
                const item = find(item => item.userId === userId, personOptions)
                if (!item) {
                    return preVal
                }
                if (item.state === AppUserStatus.DEPART) {
                    return [...preVal, { ...item, username: `${item.username}(已注销)` }]
                }
                return [...preVal, item]
            },
            [],
            usedValue
        )
    }, [usedValue, personOptions])

    const isEmptyValue = useMemo(() => currentTags.length === 0, [currentTags.length])

    const handleMultiChange = useCallback(
        (value: string[]) => {
            setValue(value)
            onCellChange?.({ type: 'person', value })
        },
        [onCellChange]
    )

    const handleRemove = useCallback(
        (id: string) => {
            handleMultiChange(filter(item => item !== id, usedValue))
        },
        [handleMultiChange, usedValue]
    )

    return (
        <SC.Container data-field-border={opened}>
            <SC.Wrapper onClick={() => !readOnly && setOpened(true)}>
                <SC.TagWrapper>
                    {currentTags.map(item => (
                        <TagItemPreviewer key={item.userId} data={item} isHiddenUserId clearable={!readOnly} onRemove={handleRemove} />
                    ))}
                    {isEmptyValue && <SelectPlaceHolder label={placeholder} />}
                </SC.TagWrapper>
                <CM.IconWrapper>{!readOnly && <IconFont type="ArrowDownSmall" color="var(--color-gray-500)" size={16} />}</CM.IconWrapper>
            </SC.Wrapper>
            <PersonDrawer
                title={title}
                value={usedValue}
                target={pageTarget}
                selectOptions={currentPersonOptions.filter(user => user.state !== AppUserStatus.DEPART)}
                isMultiple={multiple}
                onChange={handleMultiChange}
                opened={opened}
                onClose={() => setOpened(false)}
            />
        </SC.Container>
    )
}
