import type { FieldType } from '@lighthouse/core'
import cls from 'classnames'
import React, { memo, useMemo } from 'react'

import {
    isAggregationValue,
    isCheckboxValue,
    isDateListValue,
    isFileValue,
    isFormulaValue,
    isIdsValue,
    isNumberListValue,
    isRichTextValue,
    isSimpleValue
} from '../../utils/helper'
import { Placeholder } from '../PlaceHolder'
import { AggregationValuePreviewer } from './AggregationValuePreviewer'
import { CheckboxValuePreviewer } from './CheckboxValuePreviewer'
import { DateValuePreviewer } from './DateValuePreviewer'
import { DepartmentValuePreviewer } from './DepartmentValuePreviewer'
import { FileValuePreviewer } from './FileValuePreviewer'
import { FormulaValuePreviewer } from './FormulaValuePreviewer'
import { IdValuePreviewer } from './IdValuePreviewer'
import { NotesValuePreviewer } from './NotesValuePreviewer'
import { NumberValuePreviewer } from './NumberValuePreviewer'
import { PersonValuePreviewer } from './PersonValuePreviewer'
import { RoleValuePreviewer } from './RoleValuePreviewer'
import { SelectGenerationByTextValuePreviewer } from './SelectGenerationByTextValuePreviewer'
import { SelectValuePreviewer } from './SelectValuePreviewer'
import { SimpleTextValuePreviewer } from './SimpleTextValuePreviewer'
import * as SC from './styles'
import { TextGenerationValuePreviewer } from './TextGenerationValuePreviewer'
import { TextValuePreviewer } from './TextValuePreviewer'
import type { FieldValuePreviewerProps } from './types'
import { VideoValuePreviewer } from './VideoValuePreviewer'

// 以下字段类型需要单独处理垂直居中
const SPECIAL_FIELD_TYPE = new Set<Partial<FieldType>>(['file', 'video', 'select'])

const FieldValuePreviewer: React.FC<FieldValuePreviewerProps> = ({
    field,
    data,
    isWrap,
    previewHost = 'dataSource',
    enableEdit = true,
    width = 120,
    itemClickable = true,
    visiblePersonDescribe = true,
    justifyContent,
    placeholder,
    styleConditions,
    isBold,
    disabled,
    aiFieldStatus,
    isHasCurrentUser,
    isShowDepartmentId,
    isShowRoleId,
    onCellChange,
    onAiGeneration
}) => {
    const isPreviewAsCell = previewHost === 'dataSource' || previewHost === 'selectTable'

    const idFieldStyle = useMemo(() => {
        if (previewHost === 'selectTable') {
            return {
                isBold
            }
        }
        return {
            color: 'var(--color-gray-400)',
            isBold: true
        }
    }, [isBold, previewHost])

    const valueContent = useMemo(() => {
        const { type } = field
        if (placeholder && !data && data !== 0) {
            const isSpecialField = SPECIAL_FIELD_TYPE.has(type)
            return <Placeholder style={isSpecialField ? { marginTop: 3 } : {}} value={placeholder} />
        }

        switch (type) {
            case 'id': {
                return (
                    <IdValuePreviewer
                        field={field}
                        data={isSimpleValue(data) ? data : ''}
                        isWrap={isWrap}
                        width={width}
                        color={idFieldStyle.color}
                        isBold={idFieldStyle.isBold}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'textGeneration': {
                return (
                    <TextGenerationValuePreviewer
                        field={field}
                        data={isSimpleValue(data) ? data : ''}
                        isWrap={isWrap}
                        width={width}
                        isBold={isBold}
                        aiFieldStatus={aiFieldStatus}
                        styleConditions={styleConditions}
                        onAiGeneration={onAiGeneration}
                    />
                )
            }
            case 'selectGenerationByText': {
                const newData = isIdsValue(data) ? data : []
                return (
                    <SelectGenerationByTextValuePreviewer
                        field={field}
                        data={newData}
                        isWrap={isWrap}
                        width={width}
                        aiFieldStatus={aiFieldStatus}
                        styleConditions={styleConditions}
                        onAiGeneration={onAiGeneration}
                    />
                )
            }

            case 'url':
            case 'phoneNumber':
            case 'email': {
                return (
                    <SimpleTextValuePreviewer
                        field={field}
                        data={isSimpleValue(data) ? data : ''}
                        isWrap={isWrap}
                        width={width}
                        isBold={isBold}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'text': {
                return (
                    <TextValuePreviewer
                        field={field}
                        data={isSimpleValue(data) ? data : ''}
                        isWrap={isWrap}
                        width={width}
                        isBold={isBold}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'select': {
                const newData = isIdsValue(data) ? data : []
                if (placeholder && newData.length === 0) {
                    return <Placeholder style={{ marginTop: 3 }} value={placeholder} />
                }
                return (
                    <SelectValuePreviewer
                        justifyContent={justifyContent}
                        field={field}
                        width={width}
                        data={newData}
                        isWrap={isWrap}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'date': {
                return (
                    <DateValuePreviewer
                        field={field}
                        data={isDateListValue(data) ? data : undefined}
                        isWrap={isWrap}
                        width={width}
                        isBold={isBold}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'number': {
                return (
                    <NumberValuePreviewer
                        field={field}
                        data={isNumberListValue(data) ? data : undefined}
                        width={width}
                        isWrap={isWrap}
                        isBold={isBold}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'person': {
                return (
                    <PersonValuePreviewer
                        field={field}
                        data={isIdsValue(data) ? data : []}
                        isWrap={isWrap}
                        width={width}
                        justifyContent={justifyContent}
                        styleConditions={styleConditions}
                        visibleDescribe={visiblePersonDescribe}
                        isHasCurrentUser={isHasCurrentUser}
                    />
                )
            }
            case 'file': {
                const newData = isFileValue(data) ? data : []
                if (placeholder && newData.length === 0) {
                    return <Placeholder style={{ marginTop: 3 }} value={placeholder} />
                }
                return (
                    <FileValuePreviewer
                        field={field}
                        data={newData}
                        isWrap={isWrap}
                        width={width}
                        enableEdit={enableEdit}
                        itemClickable={itemClickable}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'video': {
                const newData = isFileValue(data) ? data : []
                if (placeholder && newData.length === 0) {
                    return <Placeholder style={{ marginTop: 3 }} value={placeholder} />
                }
                return (
                    <VideoValuePreviewer
                        field={field}
                        data={newData}
                        isWrap={isWrap}
                        width={width}
                        enableEdit={enableEdit}
                        itemClickable={itemClickable}
                        styleConditions={styleConditions}
                    />
                )
            }
            case 'role': {
                return (
                    <RoleValuePreviewer
                        field={field}
                        data={isIdsValue(data) ? data : []}
                        isWrap={isWrap}
                        width={width}
                        styleConditions={styleConditions}
                        isShowId={isShowRoleId}
                        // visibleDescribe={visiblePersonDescribe}
                    />
                )
            }
            case 'formula': {
                return (
                    <FormulaValuePreviewer
                        field={field}
                        width={width}
                        data={isFormulaValue(data) ? data : ''}
                        isWrap={isWrap}
                        isBold={isBold}
                        styleConditions={styleConditions}
                        isHasCurrentUser={isHasCurrentUser}
                    />
                )
            }

            case 'checkbox': {
                return (
                    <CheckboxValuePreviewer
                        field={field}
                        data={isCheckboxValue(data) ? data : undefined}
                        isWrap={isWrap}
                        width={width}
                        disabled={disabled}
                        styleConditions={styleConditions}
                        onCellChange={onCellChange}
                    />
                )
            }

            case 'notes': {
                const noteData = isRichTextValue(data) ? data : undefined
                return <NotesValuePreviewer field={field} data={noteData} styleConditions={styleConditions} />
            }
            case 'department': {
                return (
                    <DepartmentValuePreviewer
                        field={field}
                        data={isIdsValue(data) ? data : []}
                        justifyContent={justifyContent}
                        isWrap={isWrap}
                        width={width}
                        isShowId={isShowDepartmentId}
                    />
                )
            }
            case 'aggregation': {
                return (
                    <AggregationValuePreviewer
                        field={field}
                        data={isAggregationValue(data) ? data : ''}
                        isWrap={isWrap}
                        width={width}
                        isBold={isBold}
                        styleConditions={styleConditions}
                    />
                )
            }
            default: {
                return <></>
            }
        }
    }, [
        field,
        placeholder,
        data,
        isWrap,
        width,
        idFieldStyle.color,
        idFieldStyle.isBold,
        styleConditions,
        isBold,
        aiFieldStatus,
        onAiGeneration,
        justifyContent,
        visiblePersonDescribe,
        isHasCurrentUser,
        enableEdit,
        itemClickable,
        isShowRoleId,
        disabled,
        onCellChange,
        isShowDepartmentId
    ])
    return isPreviewAsCell ? (
        <SC.ValueContainer className={cls('overflow', { 'enable-edit': enableEdit })}>{valueContent}</SC.ValueContainer>
    ) : (
        <SC.ValuePreview>{valueContent}</SC.ValuePreview>
    )
}

export default memo(FieldValuePreviewer)
