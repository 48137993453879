import type { DataSourceAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import useSWR from 'swr'

import { dataSourceDepartmentListAtom, dataSourceRoleListAtom, dataSourceUserListAtom } from '@/atoms/dataSource/state'
import * as srv from '@/services'

/**
 * @description 获取数据源用户列表
 * @export
 * @return {*}
 */
export function useDataSourceUsers() {
    return useAtomData(dataSourceUserListAtom)
}


/**
 * @description 获取数据源角色列表
 * @export
 * @return {*}
 */
export function useDataSourceRoles() {
    return useAtomData(dataSourceRoleListAtom)
}

/**
 * @description 获取数据源部门列表
 * @export
 * @return {*}
 */

export function useDataSourceDepartments() {
    return useAtomValue(dataSourceDepartmentListAtom)
}

/**
 * @description 获取数据源配置
 * @export
 * @return {*}
 */
export function useSWRDataSourceMeta(appId: string, dsId: string, pageId: string) {
    const { data, mutate } = useSWR<DataSourceAbstract | undefined>(
        `/app/getDsMeta/${appId} - ${dsId}`,
        () => {
            if (!dsId || !appId) {
                return
            }
            return srv.getDsMeta(appId, dsId, pageId)
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}
