import { useUncontrolled } from '@lighthouse/tools'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { useApplicationContext } from '../../../../contexts'
import { getDepartmentTree } from '../../../DepartmentSelect'
import { DepartmentTreeDropDown } from '../../../DepartmentSelect/DepartmentTreeDropDown'

interface CustomDepartmentProps {
    value?: string[]
    onChange: (value: string[]) => void
    onClose: () => void
}

export const CustomDepartments: React.FunctionComponent<CustomDepartmentProps> = ({ value: data, onChange, onClose }) => {
    const [search, setSearch] = useState('')
    const [value, handleChangeValue] = useUncontrolled<string[]>({ defaultValue: data })

    const { departmentOptions: options } = useApplicationContext()

    const optionsTree = useMemo(
        () => (search ? options?.filter(v => v.name.toLowerCase().includes(search.toLowerCase())) : getDepartmentTree(options)),
        [options, search]
    )

    const handleSearchChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(ev.target.value)
    }, [])

    const handleChange = useCallback(() => {
        onChange?.(value)
        onClose()
        setSearch('')
    }, [onChange, onClose, value])

    useUpdateEffect(() => {
        handleChangeValue(data ?? [])
    }, [data])

    return (
        <DepartmentTreeDropDown
            options={optionsTree}
            flatOptions={options}
            value={value}
            search={search}
            onChange={handleChangeValue}
            onFinish={handleChange}
            onSearchChange={handleSearchChange}
        />
    )
}
