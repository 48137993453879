import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const TagCascadeSelectWrapper = styled.div``

export const TagCascadeSelectSearch = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: var(--font-size-normal);
    background-color: var(--color-gray-50);
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    min-height: 39px;
    padding: 5px 7px;
    overflow: hidden;
`

export const TagItem = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 24px;
    line-height: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 4px;
    padding: 2px 14px 2px 6px;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-black);
    background-color: var(--color-gray-200);
    border-radius: 4px;
    border: 1px solid transparent;
    &:not(.plain-container) {
        border-radius: 100px;
        padding: 0 18px 0 8px;
        /* background: #f4e7c3; */
    }
`

export const Cursor = styled.div`
    display: inline-block;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 2px;
    margin-right: 4px;
    height: 22px;
    padding: 2px 4px 2px 0;
    background-color: transparent;
    /* line-height: 22px; */

    input {
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--color-black);
        width: 100%;
        height: 100%;
    }
`

export const TagItemContent = styled.div`
    overflow: hidden;
    /* padding-right: 6px; */
`

export const TIcon = styled(IconFont)`
    font-size: 12px;
    cursor: pointer;
    color: var(--color-gray-500);
`

export const CloseIcon = styled(IconFont)`
    position: absolute;
    right: 6px;
    top: 5px;
    font-size: 12px;
    cursor: pointer;
    color: var(--color-gray-400);
`

export const IconWrapper = styled.div<{ isOpen: boolean }>`
    color: var(--color-gray-500);
    transition: transform 0.2s ease;
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(180deg);
        `}
`

export const OptionAdder = styled.div`
    display: flex;
    padding: 4px 10px;
    align-items: center;
    cursor: pointer;
    font-size: var(--font-size-normal);
    ${backgroundTransitionOnClick}

    &.hovered {
        background-color: var(--color-gray-100);
    }
`

export const AdderText = styled.span`
    margin: 0 8px;
    color: var(--color-gray-500);
`

export const AdderOption = styled.div`
    display: inline-flex;
    align-items: center;
    border-radius: 100px;
    padding: 2px 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 4px;
    height: 22px;
    border: 1px solid var(--color-gray-200);
`
