import { Button, Empty, Input, singleTextEllipsis, Text, tinyButtons } from '@byecode/ui'
import { find, reduce } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import type { AppDepartment } from '../../types'
import { DepartmentSelectItem } from './DepartmentItem'
import * as SC from './styles'

const SCxDropDownHeader = styled.div`
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    background-color: var(--color-gray-50);
    border-radius: 8px 8px 0 0;
`

const SCxDropDownList = styled.div`
    margin: 8px 0;
    max-height: 500px;
    overflow-y: auto;
    ${tinyButtons}
`

const SCxTag = styled.div<{ isOmit?: boolean }>`
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 6px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

interface DepartmentTreeDropDownProps {
    options: AppDepartment[]
    flatOptions: AppDepartment[]
    value: string[]
    search: string
    onSearchChange: React.ChangeEventHandler<HTMLInputElement>
    onChange: (value: string[]) => void
    onFinish: () => void
}

export const DepartmentTreeDropDown: React.FunctionComponent<DepartmentTreeDropDownProps> = ({
    options,
    flatOptions,
    value,
    search,
    onChange,
    onFinish,
    onSearchChange
}) => {
    const tagList = useMemo(() => {
        return reduce<string, AppDepartment[]>(
            (preVal, departmentId) => {
                const option = find(item => item.id === departmentId, flatOptions)
                return option ? [...preVal, option] : preVal
            },
            [],
            value
        )
    }, [flatOptions, value])

    const handleMultiple = useCallback(
        (ids: string[]) => {
            const id = ids[0]
            const newIds = value.includes(id) ? value.filter(item => item !== id) : [...value, id]
            onChange(newIds)
        },
        [onChange, value]
    )

    return (
        <SC.DropDown>
            <SCxDropDownHeader>
                <SC.DropDownHeaderLeft>
                    <SC.TagWrapper>
                        {tagList.map(tag => (
                            <SCxTag key={tag.id}>
                                <Text color="var(--color-black)" size={14} inline>
                                    {tag.name}
                                </Text>
                                <Text color="var(--color-gray-400)" size={14} inline>
                                    {tag.id}
                                </Text>
                            </SCxTag>
                        ))}
                        <Input
                            value={search}
                            styles={{
                                wrapper: {
                                    borderStyle: 'none',
                                    backgroundColor: 'transparent!important',
                                    flex: 1,
                                    minWidth: 70
                                }
                            }}
                            onChange={onSearchChange}
                            placeholder="搜索"
                            size="xs"
                        />
                    </SC.TagWrapper>
                </SC.DropDownHeaderLeft>
                <Button size="sm" type="primary" style={{ flexShrink: 0 }} onClick={onFinish}>
                    确认
                </Button>
            </SCxDropDownHeader>
            <SCxDropDownList>
                {options.length === 0 && <Empty styles={{ root: { height: 100 } }} icon="SpaceTeam" description="没有部门" />}
                {options.map(option => (
                    <DepartmentSelectItem
                        key={option.id}
                        data={option}
                        level={0}
                        onChange={handleMultiple}
                        renderRightSection={childDepartment => {
                            return value.includes(childDepartment.id) ? <SC.Icon type="Tick" color="var(--color-theme-7)" /> : null
                        }}
                    />
                ))}
            </SCxDropDownList>
        </SC.DropDown>
    )
}
