import type { ColumnType } from '@byecode/ui'
import { Button, Flex, IconFont, Table } from '@byecode/ui'
import React, { useMemo } from 'react'

import type { AppImportUser, ImportUser } from '../../types'
import * as SC from './styles'

interface FinishProps {
    data?: AppImportUser
    onClose?: () => void
}

const columns: ColumnType<ImportUser>[] = [
    {
        title: 'excel行数',
        key: 'excelRow'
        // width: 320
    },
    {
        title: '姓名',
        key: 'name'
    },
    {
        title: '手机号',
        key: 'mobile'
    },
    {
        title: '导入失败原因',
        key: 'reason',
        formatter: (value, record) => <SC.ErrorText>{value}</SC.ErrorText>
    }
]

export const Finish: React.FC<FinishProps> = ({ data, onClose }) => {
    const { failUsers, successCount, failCount } = useMemo(() => data || { failUsers: [], successCount: 0, failCount: 0 }, [data])
    return (
        <>
            <SC.Title>导入结果</SC.Title>

            <SC.Card>
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex direction="column" alignItems="center">
                        <IconFont type="TickCircle" size={20} fill="var(--color-green-500)" />
                        <SC.ResultText>导入成功{successCount}人</SC.ResultText>
                    </Flex>
                    <Flex direction="column" alignItems="center">
                        <IconFont type="ErrorCircle" size={20} fill="var(--color-red-500)" />
                        <SC.ResultText>导入失败{failCount}人</SC.ResultText>
                    </Flex>
                </Flex>
            </SC.Card>

            <SC.SecondTitle>导入失败的用户</SC.SecondTitle>
            <SC.Description>以下为导入失败的成员列表，修改后重新导入</SC.Description>
            <Table
                data={failUsers}
                pagination={false}
                styles={{
                    root: { border: '1px solid var(--color-gray-200)', marginBottom: 32, borderRadius: 8, overflow: 'hidden' },
                    th: { backgroundColor: 'var(--color-gray-50)', borderBottom: '1px solid var(--color-gray-200)' },
                    tr: {
                        '&:last-child': {
                            td: {
                                borderBottom: 'none'
                            }
                        }
                    },
                    table: { position: 'relation' },
                    header: { position: 'sticky', top: 0 },
                    content: { background: 'var(--color-gray-50)', overflow: 'auto', maxHeight: 400 }
                }}
                columns={columns}
                rowKey="mobile"
            />

            <SC.Footer>
                <Button size="lg" onClick={onClose}>
                    取消
                </Button>
                <Button size="lg" type="primary" onClick={onClose}>
                    确认
                </Button>
            </SC.Footer>
        </>
    )
}
