import type { TypeInstanceMap } from '@lighthouse/core'
import { type FieldType, type SystemVariableValue, type ValueVariable, VariableType } from '@lighthouse/core'

import { FieldIconTypeMap } from '../../constants'
import type { VariableSourceType } from '../../types'
import { getFieldIcon } from '../../utils'
import type { VariableTree } from './types'

export const ignoreEleId = 'default-custom-value'

export const sourceNameMap: Record<VariableSourceType, string> = {
    page: '从页面获取的数据',
    module: '从容器获取的数据',
    parentPage: '从上游页面获取的数据',
    parentNode: '从上游节点获取的数据',
    parentJoinNode: '',
    form: '表单创建的数据'
}

export const sourceDescriptionMap: Record<VariableSourceType, string> = {
    page: '当前页面',
    module: '容器',
    parentPage: '上游页面',
    parentNode: '上游节点',
    parentJoinNode: '直接上游节点',
    form: '表单创建的数据'
}

export const pageSourceVariableType = ['parentPage', 'page']

export const FILTER_DATE_SYSTEM_LIST_1: SystemVariableValue[] = [
    'TODAY',
    'TOMORROW',
    'YESTERDAY',
    'THIS_WEEK',
    'LAST_WEEK',
    'THIS_MONTH',
    'LAST_MONTH',
    'THIS_YEAR',
    'PAST_SEVEN_DAYS',
    'FUTURE_SEVEN_HEAVENS',
    'PAST_THIRTY_DAYS',
    'FUTURE_THIRTY_HEAVENS'
]

export const FILTER_DATE_SYSTEM_LIST_2: SystemVariableValue[] = [
    'TODAY',
    'TOMORROW',
    'YESTERDAY',
    'THIS_WEEK',
    'BEFORE_SOMEWHAT_DAYS',
    'AFTER_SOMEWHAT_DAYS'
]

export const FieldInputTitleMap: Record<FieldType, string> = {
    id: '自动编号',
    text: '文本',
    number: '数值',
    email: '邮箱',
    phoneNumber: '电话',
    url: '链接',
    select: '选项',
    date: '日期',
    file: '附件',
    video: '视频',
    person: '人员',
    notes: '笔记',
    formula: '计算',
    role: '归属角色',
    checkbox: '是或否',
    department: '部门',
    textGeneration: '生成文本',
    textGenerationByAudio: '音频转文本',
    textGenerationByImage: '图像转文本',
    booleanGenerationByText: '文本转布尔',
    selectGenerationByText: '文本转选项',
    dateGenerationByText: '文本转日期',
    numberGenerationByText: '文本转数字',
    textGenerationByFile: '文件转文本',
    aggregation: '聚合'
}

export const AggregationInnerTypeToFieldType: Record<TypeInstanceMap, FieldType> = {
    TEXT: 'text',
    NULL: 'text',
    DATE: 'date',
    BOOL: 'checkbox',
    NUMBER: 'number',
    RICHTEXT: 'notes',
    ARRAY: 'text'
}

export const variableFieldInfoMap: Record<
    FieldType,
    {
        name: string
        icon: string
    }
> = {
    id: {
        name: '输入具体值',
        icon: FieldIconTypeMap['id']
    },
    text: {
        name: '输入具体值',
        icon: FieldIconTypeMap['text']
    },
    number: {
        name: '输入具体值',
        icon: FieldIconTypeMap['number']
    },
    email: {
        name: '输入具体值',
        icon: FieldIconTypeMap['email']
    },
    phoneNumber: {
        name: '输入具体值',
        icon: FieldIconTypeMap['phoneNumber']
    },
    url: {
        name: '输入具体值',
        icon: FieldIconTypeMap['url']
    },
    select: {
        name: '选择选项',
        icon: FieldIconTypeMap['select']
    },
    date: {
        name: '选择具体日期',
        icon: FieldIconTypeMap['date']
    },
    file: {
        name: '',
        icon: ''
    },
    video: {
        name: '',
        icon: ''
    },
    person: {
        name: '选择人员',
        icon: FieldIconTypeMap['person']
    },
    notes: {
        name: '输入具体值',
        icon: FieldIconTypeMap['notes']
    },
    formula: {
        name: '输入具体值',
        icon: FieldIconTypeMap['formula']
    },
    role: {
        name: '选择角色',
        icon:  FieldIconTypeMap['role']
    },
    checkbox: {
        name: '选择是或否',
        icon: FieldIconTypeMap['checkbox']
    },
    department: {
        name: '选择部门',
        icon: FieldIconTypeMap['department']
    },
    textGeneration: {
        name: '输入具体值',
        icon: FieldIconTypeMap['textGeneration']
    },
    textGenerationByAudio: {
        name: '',
        icon: ''
    },
    textGenerationByImage: {
        name: '',
        icon: ''
    },
    booleanGenerationByText: {
        name: '',
        icon: ''
    },
    selectGenerationByText: {
        name: '',
        icon: ''
    },
    dateGenerationByText: {
        name: '',
        icon: ''
    },
    numberGenerationByText: {
        name: '',
        icon: ''
    },
    textGenerationByFile: {
        name: '',
        icon: ''
    },
    aggregation: {
        name: '',
        icon: ''
    }
}

export const DEFAULT_FILTER_VALUE_VARIABLE: ValueVariable = { type: VariableType.VALUE }

export const CURRENT_PAGE = 'CURRENT_PAGE'

export const PageLinkOption: VariableTree = {
    id: CURRENT_PAGE,
    icon: 'PropertyLink',
    dsId: '',
    title: '当前页面链接',
    type: VariableType.PAGE_LINK,
    children: []
}

export const ruleOptions = [
    {
        label: '所有',
        value: 'AND'
    },
    {
        label: '任一',
        value: 'OR'
    }
]
