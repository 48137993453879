import { Button } from '@byecode/ui'
import type { ApplicationSettingAuthentication, DataSourceAbstract, FieldADTValue } from '@lighthouse/core'
import { ApplicationSettingPerfectMode } from '@lighthouse/core'
import { Text } from '@mantine/core'
import cls from 'classnames'
import { find } from 'rambda'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ApplicationPreviewEnum, AppRole } from '../../../types'
import { isEmptyCellValue } from '../../../utils'
import type { CascadeParam, RelativeFieldParam } from '../../FieldInput'
import { TagIcon } from '../../TagIcon'
import { getFields } from '../helps'
import * as CM from '../styles'
import type { AccountPerfectForm, AccountRegisterForm } from '../types'
import { PerfectFields } from './PerfectFields'
import * as SC from './styles'

interface PerfectProps extends RelativeFieldParam, CascadeParam {
    authentication: ApplicationSettingAuthentication
    dataSource?: DataSourceAbstract
    previewType?: ApplicationPreviewEnum
    style?: React.CSSProperties
    // 在什么时候打开的
    mode: 'register' | 'login'
    roles?: AppRole[]
    onBack?: () => void
}

export const PerfectInfo: React.FunctionComponent<PerfectProps> = ({
    authentication,
    dataSource,
    previewType,
    style,
    roles = [],
    mode,
    relativeDataSource,
    dataSourceList,
    onBack,
    onFetchCascadeOptions,
    onFetchDataSource,
    onLoadMoreData
}) => {
    const { perfect } = authentication
    const { mode: perfectMode, roleSetting = [], title, describe, selectRoleDescribe, selectRoleTitle } = perfect
    const {
        control,
        setValue,
        getValues,
        watch,
        formState: { errors }
    } = useFormContext<AccountRegisterForm | AccountPerfectForm>()

    const { t } = useTranslation()

    const isGroupSetting = useMemo(() => perfectMode === ApplicationSettingPerfectMode.ROLE, [perfectMode])

    const [isSelectRole, setIsSelectRole] = useState(false)
    const [fields, groupId] = watch(['fields', 'roleId'])

    const isAllEmpty = fields?.every(field =>
        isEmptyCellValue({ value: field.value, type: dataSource?.schema?.[field.fieldId]?.type ?? 'text' } as FieldADTValue)
    )

    const currentGroup = useMemo(() => find(setting => setting.roleId === groupId, roleSetting), [groupId, roleSetting])

    useEffect(() => {
        setIsSelectRole(isGroupSetting)
    }, [isGroupSetting])

    const isShownBackBtn = useMemo(() => {
        if (mode === 'login') {
            return isGroupSetting && !isSelectRole
        }
        return mode === 'register'
    }, [isGroupSetting, isSelectRole, mode])

    const contentEle = useMemo(() => {
        if (isSelectRole) {
            return (
                <>
                    <SC.Title>{selectRoleTitle}</SC.Title>
                    <SC.Describe>{selectRoleDescribe}</SC.Describe>
                    <Controller
                        name="roleId"
                        control={control}
                        render={({ field }) => (
                            <SC.TagList>
                                {roleSetting.map(setting => {
                                    const isActive = setting.roleId === field.value
                                    const groupName = find(item => item.id === setting.roleId, roles)?.name
                                    return (
                                        <SC.Tag
                                            key={setting.roleId}
                                            className={cls({ active: isActive })}
                                            onClick={() => {
                                                field.onChange(setting.roleId)
                                                setValue('fields', getFields(setting.fields))
                                            }}
                                        >
                                            <Text lineClamp={1}>{groupName}</Text>
                                            {isActive && <CM.Icon type="TickCircle" size={20} color="var(--color-green-500)" />}
                                        </SC.Tag>
                                    )
                                })}
                            </SC.TagList>
                        )}
                    />

                    {currentGroup?.isShown ? (
                        <Button
                            block
                            style={{ height: 44, marginTop: 36, backgroundColor: 'var(--color-app-main)', borderStyle: 'none' }}
                            type="primary"
                            radius={10}
                            htmlType="button"
                            onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                getValues('roleId') && setIsSelectRole(false)
                            }}
                        >
                            {t('next')}
                        </Button>
                    ) : (
                        <Button
                            block
                            style={{ height: 44, marginTop: 36, backgroundColor: 'var(--color-app-main)', borderStyle: 'none' }}
                            type="primary"
                            radius={10}
                            htmlType="submit"
                        >
                            {t('sure')}
                        </Button>
                    )}
                </>
            )
        }
        return (
            <>
                <SC.Title>{title}</SC.Title>
                <SC.Describe>{describe}</SC.Describe>
                <PerfectFields
                    errors={errors}
                    dataSource={dataSource}
                    previewType={previewType}
                    relativeDataSource={relativeDataSource}
                    dataSourceList={dataSourceList}
                    onFetchCascadeOptions={onFetchCascadeOptions}
                    onFetchDataSource={onFetchDataSource}
                    onLoadMoreData={onLoadMoreData}
                />
                <Button
                    block
                    style={{ height: 44, marginTop: 36, backgroundColor: 'var(--color-app-main)', borderStyle: 'none' }}
                    type="primary"
                    htmlType="submit"
                    radius={10}
                    disabled={isAllEmpty}
                    onClick={() => setIsSelectRole(false)}
                >
                    {t('sure')}
                </Button>
            </>
        )
    }, [
        isSelectRole,
        title,
        describe,
        errors,
        dataSource,
        previewType,
        relativeDataSource,
        dataSourceList,
        onFetchCascadeOptions,
        onFetchDataSource,
        onLoadMoreData,
        isAllEmpty,
        t,
        selectRoleTitle,
        selectRoleDescribe,
        control,
        currentGroup?.isShown,
        roleSetting,
        roles,
        setValue,
        getValues
    ])

    return (
        <CM.Container style={style}>
            {isShownBackBtn && (
                <TagIcon
                    size={28}
                    iconSize={20}
                    radius={100}
                    enableHover
                    icon="ArrowLeftSmall"
                    iconColor="var(--color-gray-400)"
                    background="var(--color-gray-100)"
                    style={{
                        position: 'absolute',
                        left: 20,
                        top: 20
                    }}
                    onClick={() => (mode === 'register' ? onBack?.() : setIsSelectRole(true))}
                />
            )}
            {contentEle}
        </CM.Container>
    )
}
