import { backgroundTransitionOnClick, IconFont, singleTextEllipsis } from '@byecode/ui'
import type { FieldADTValue, FieldProtocol, PersonValue, RoleValue } from '@lighthouse/core'
import { filter } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../contexts'
import type { AppRole } from '../../types'
import type { SelectChangeEvent, TagSelectOption } from '../TagSelect'
import { TagSelect } from '../TagSelect'
import * as SC from './styles'

interface RoleValueEditorProps {
    recordId: string
    field: FieldProtocol
    data: PersonValue
    roleOptions?: AppRole[]
    style?: React.CSSProperties
    autoFocus?: boolean
    isHiddenId?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: (isActive?: boolean) => void
}

const SCxTagItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4px;
    overflow: hidden;
`

const SCxListItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    margin-right: 2px;
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

const SCxItemLabel = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxItemLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxItemName = styled.div`
    flex: 1;
    ${singleTextEllipsis}
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

const SCxListItemDescribe = styled.div`
    margin-left: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    max-width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxTagItemDescribe = styled.div`
    margin-left: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    overflow: hidden;
    text-overflow: ellipsis;
`

export const RoleValueEditor: React.FC<RoleValueEditorProps> = ({
    recordId,
    field,
    data,
    style,
    autoFocus,
    isHiddenId,
    onCellChange,
    onClose
}) => {
    const { roleOptions } = useApplicationContext()
    const [roleValues, setRoleValues] = React.useState<RoleValue>(data)
    // const {
    //     person: { multiple }
    // } = field
    const options = useMemo(() => {
        return roleOptions.map(({ id, name }) => ({
            label: name,
            value: id
        }))
    }, [roleOptions])

    const renderTagItem = useCallback((item: TagSelectOption) => {
        // const background = getColorById(item.value)
        return (
            <SCxTagItemWrapper>
                <SCxItemLabel>
                    <SCxItemName>{item.label}</SCxItemName>
                    {!isHiddenId && <SCxTagItemDescribe>{item.value}</SCxTagItemDescribe>}
                </SCxItemLabel>
            </SCxTagItemWrapper>
        )
    }, [isHiddenId])

    const renderEmptyItem = useCallback((id: string) => {
        return (
            <SCxTagItemWrapper>
                <SCxItemLabel>
                    <SCxItemName>无权限角色</SCxItemName>
                    {!isHiddenId && <SCxTagItemDescribe>{id}</SCxTagItemDescribe>}
                </SCxItemLabel>
            </SCxTagItemWrapper>
        )
    }, [isHiddenId])

    const renderListItem = useCallback((item: TagSelectOption) => {
        // const background = getColorById(item.value)
        return (
            <SCxListItemWrapper>
                <SCxItemLeft>
                    {/* {renderUserAvatar(item)} */}
                    <SCxItemName>{item.label}</SCxItemName>
                </SCxItemLeft>
                {item.describe && <SCxListItemDescribe>{item.describe}</SCxListItemDescribe>}
            </SCxListItemWrapper>
        )
    }, [])

    const handleChange = useCallback(
        (ev: SelectChangeEvent) => {
            const { action, option } = ev
            switch (action) {
                case 'add': {
                    const value: string[] = [option]
                    setRoleValues(value)
                    onCellChange?.(recordId, { ...field, type: 'role', value })
                    onClose?.()
                    break
                }
                case 'delete': {
                    let value: string[] = []
                    const newRoles = filter(val => {
                        return val !== option
                    }, roleValues)
                    value = newRoles
                    setRoleValues(value)
                    onCellChange?.(recordId, { ...field, type: 'role', value })
                    onClose?.()
                    break
                }
                default: {
                    break
                }
            }
        },
        [onCellChange, recordId, field, onClose, roleValues]
    )

    return (
        <SC.TagSelectWrapper style={style}>
            <TagSelect
                sortable={false}
                description="从角色中选择"
                options={options}
                value={roleValues}
                autoFocus={autoFocus}
                onChange={handleChange}
                emptyItemRender={renderEmptyItem}
                tagItemRender={renderTagItem}
                listItemRender={renderListItem}
            />
        </SC.TagSelectWrapper>
    )
}
