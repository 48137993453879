import { ClickAwayListener } from '@byecode/ui'
import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    Field,
    FieldADTValue,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    ViewField
} from '@lighthouse/core'
import type { ApplicationPreviewEnum, UseUploadFileSParameter } from '@lighthouse/shared'
import {
    getIdByCellDataKey,
    getIsAppointField,
    NO_EDIT_SYSTEM_FIELD,
    notEditableFieldTypes,
    selectionState,
    universalHotKeys,
    VIEW_CELL_DATA_KEY
} from '@lighthouse/shared'
import type { UploadyProps } from '@rpldy/uploady'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import AdvancedTableBody from './AdvancedTableBody'
import { TablePopover } from './AdvancedTablePopover'
import { AdvancedTableTooltip } from './AdvancedTableTooltip'

export interface AdvancedTableContentProps extends ActionsProtocol {
    blockId: string
    checkable?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    viewFields: ViewField[]
    dataSourceInfo: DataSourceAbstract
    records: RecordLikeProtocol[]
    scrollRef: React.RefObject<HTMLDivElement>
    blockWidth: number
    selectedRecords?: string[]
    highLightRules?: HighLightConditions
    previewType?: ApplicationPreviewEnum
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    primaryDataSourceFieldIds?: Set<string>
    getUploadOptions: (fieldId: string, recordId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    getRichTextUploadOptions: (fieldId: string, recordId: string) => UploadyProps
    getVideoUploadOptions: (fieldId: string, recordId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onRecordEdit?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export interface AdvancedTableContentState {
    currentRecordId: string
    currentWidth?: number
    currentField?: Field
    popperAnchorElement?: HTMLDivElement
    editorElement?: HTMLDivElement
}

export interface AdvancedTableTooltipState {
    toolTipAnchorElement?: HTMLDivElement
    message?: string
}

export const SCxTableAdvancedContent = styled.div`
    height: 100%;
    min-width: 100%;
    width: fit-content;
    outline: none;
`

export const AdvancedTableContent: React.FC<AdvancedTableContentProps> = ({
    blockId,
    actions,
    checkable,
    recordOpenable,
    recordEditOpenable,
    recordDeleteAble,
    viewFields,
    dataSourceInfo,
    records,
    scrollRef,
    blockWidth,
    selectedRecords,
    highLightRules,
    previewType,
    aiFieldStatusListAtom,
    primaryDataSourceFieldIds,
    getUploadOptions,
    getRichTextUploadOptions,
    getVideoUploadOptions,
    onRecordSelect,
    onRecordClick,
    onCellChange,
    onCellUpdate,
    onRecordDelete,
    onRecordEdit,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const { schema, appId: dsAppId, id: dsId } = dataSourceInfo
    const [state, setState] = useState<AdvancedTableContentState>({
        currentRecordId: '',
        currentWidth: 160,
        currentField: undefined,
        popperAnchorElement: undefined,
        editorElement: undefined
    })

    const [tooltipState, setTooltipState] = useState<AdvancedTableTooltipState>({
        toolTipAnchorElement: undefined
    })

    const { currentRecordId, currentWidth, currentField, editorElement, popperAnchorElement } = state

    useEffect(() => {
        if (tooltipState.toolTipAnchorElement) {
            const timer = setTimeout(() => {
                setTooltipState({
                    toolTipAnchorElement: undefined
                })
            }, 1500)
            return () => clearTimeout(timer)
        }
    }, [setTooltipState, tooltipState.toolTipAnchorElement])

    const handleOpenTooltip = useCallback(
        (data: AdvancedTableTooltipState) => {
            setTooltipState(data)
        },
        [setTooltipState]
    )

    const handleOpenEditor = useCallback(
        (data: AdvancedTableContentState) => {
            setState(data)
        },
        [setState]
    )

    const handleClose = useCallback(() => {
        setState({
            currentRecordId: '',
            currentWidth: 160,
            currentField: undefined,
            popperAnchorElement: undefined,
            editorElement: undefined
        })
    }, [])

    const handleBlur = useCallback(() => {
        if (!scrollRef.current) {
            return
        }
        const cellActiveDoms = scrollRef.current.querySelectorAll('.cell-active')
        cellActiveDoms.forEach(cellActiveDom => {
            cellActiveDom.classList.remove('cell-active')
        })
    }, [scrollRef])

    useHotkeys<HTMLDivElement>(
        universalHotKeys,
        ev => {
            // const recordPool = useAtomValue(recordPoolAtom)
            const { cellCheckedId } = selectionState.state
            if (!scrollRef.current) {
                return
            }
            const cDom = scrollRef.current.querySelector(`[${VIEW_CELL_DATA_KEY}='${cellCheckedId}']`) as HTMLDivElement
            if (!cellCheckedId || !cDom) {
                return
            }
            const disable = cDom.getAttribute('data-disable')
            if (disable) {
                return
            }
            const anchorDom = cDom.querySelector(`[data-type='anchor']`) as HTMLDivElement
            const [_, recordId, fieldId] = getIdByCellDataKey(cellCheckedId)
            const field = schema[fieldId]
            const tableProp = find(item => item.fieldId === fieldId, viewFields)

            if (
                !primaryDataSourceFieldIds?.has(fieldId) ||
                notEditableFieldTypes.has(field?.type) ||
                getIsAppointField(fieldId, NO_EDIT_SYSTEM_FIELD) ||
                !anchorDom
            ) {
                return
            }

            ev.preventDefault()
            setState({
                popperAnchorElement: anchorDom,
                editorElement: cDom,
                currentRecordId: recordId,
                // currentData: record.content[fieldId].value,
                currentWidth: tableProp?.width || 160,
                currentField: field
            })
        },
        { enableOnContentEditable: true, enableOnTags: ['INPUT'] },
        [viewFields]
    )

    const tableTooltip = useMemo(() => {
        if (!tooltipState.toolTipAnchorElement || !tooltipState.message) {
            return null
        }
        return <AdvancedTableTooltip target={tooltipState.toolTipAnchorElement} label={tooltipState.message} />
    }, [tooltipState.message, tooltipState.toolTipAnchorElement])

    const tablePopover = useMemo(() => {
        if (!currentField) {
            return null
        }

        const currentFieldProp = find(({ fieldId }) => fieldId === currentField.id, viewFields)

        if (!currentFieldProp) {
            return null
        }

        const { required } = currentFieldProp

        return (
            <TablePopover
                scrollRef={scrollRef}
                blockId={blockId}
                popperAnchorElement={popperAnchorElement}
                editorElement={editorElement}
                dataSourceInfo={dataSourceInfo}
                recordId={currentRecordId}
                width={currentWidth}
                required={required}
                field={currentField}
                records={records}
                previewType={previewType}
                viewFields={viewFields}
                getUploadOptions={getUploadOptions}
                getRichTextUploadOptions={getRichTextUploadOptions}
                getVideoUploadOptions={getVideoUploadOptions}
                onCellChange={onCellChange}
                onCellUpdate={onCellUpdate}
                onClose={handleClose}
            />
        )
    }, [blockId, currentField, currentRecordId, currentWidth, dataSourceInfo, editorElement, getRichTextUploadOptions, getUploadOptions, getVideoUploadOptions, handleClose, onCellChange, onCellUpdate, popperAnchorElement, previewType, records, scrollRef, viewFields])

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => handleBlur()}>
            <SCxTableAdvancedContent>
                <AdvancedTableBody
                    actions={actions}
                    blockId={blockId}
                    appId={dsAppId}
                    dsId={dsId}
                    records={records}
                    checkable={checkable}
                    recordOpenable={recordOpenable}
                    recordDeleteAble={recordDeleteAble}
                    recordEditOpenable={recordEditOpenable}
                    selectedRecords={selectedRecords}
                    viewFields={viewFields}
                    schema={schema}
                    scrollRef={scrollRef}
                    highLightRules={highLightRules}
                    blockWidth={blockWidth}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                    onRecordSelect={onRecordSelect}
                    onOpenEditor={handleOpenEditor}
                    onOpenTooltip={handleOpenTooltip}
                    onRecordClick={onRecordClick}
                    onCellChange={onCellChange}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={onRecordDelete}
                    onAiGeneration={onAiGeneration}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                />
                {tablePopover}
                {tableTooltip}
            </SCxTableAdvancedContent>
        </ClickAwayListener>
    )
}
