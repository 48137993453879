import { singleTextEllipsis } from '@byecode/ui'
import type { FieldADTValue, FieldProtocol, PersonValue } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../contexts'
import type { AppDepartment } from '../../types'
import type { TagCascadeSelectOption } from '../TagCascadeSelect'
import { TagCascadeSelect } from '../TagCascadeSelect'
import * as SC from './styles'

interface DepartmentValueEditorProps {
    recordId: string
    field: FieldProtocol
    data: PersonValue
    roleOptions?: AppDepartment[]
    style?: React.CSSProperties
    autoFocus?: boolean
    isHiddenId?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: (isActive?: boolean) => void
}

const SCxTagItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4px;
    overflow: hidden;
`

const SCxItemLabel = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxItemName = styled.div`
    flex: 1;
    ${singleTextEllipsis}
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

const SCxTagItemDescribe = styled.div`
    margin-left: 4px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    overflow: hidden;
    text-overflow: ellipsis;
`

export const DepartmentValueEditor: React.FC<DepartmentValueEditorProps> = ({
    recordId,
    field,
    data,
    style,
    autoFocus,
    isHiddenId,
    onCellChange,
    onClose
}) => {
    const { departmentOptions } = useApplicationContext()

    const options = useMemo(() => {
        return departmentOptions.map(({ id, name, parentId }) => ({
            label: name,
            value: id,
            parentId
        }))
    }, [departmentOptions])

    const renderTagItem = useCallback((item: TagCascadeSelectOption) => {
        return (
            <SCxTagItemWrapper>
                <SCxItemLabel>
                    <SCxItemName>{item.label}</SCxItemName>
                   {!isHiddenId && <SCxTagItemDescribe>{item.value}</SCxTagItemDescribe>}
                </SCxItemLabel>
            </SCxTagItemWrapper>
        )
    }, [isHiddenId])

    const renderEmptyItem = useCallback((id: string) => {
        return (
            <SCxTagItemWrapper>
                <SCxItemLabel>
                    <SCxItemName>找不到部门</SCxItemName>
                    {!isHiddenId && <SCxTagItemDescribe>{id}</SCxTagItemDescribe>}
                </SCxItemLabel>
            </SCxTagItemWrapper>
        )
    }, [isHiddenId])

    const handleChange = useCallback(
        (val: string[]) => {
            onCellChange?.(recordId, { ...field, type: 'department', value: val })
        },
        [onCellChange, recordId, field]
    )

    return (
        <SC.TagCascadeSelectWrapper style={style}>
            <TagCascadeSelect
                options={options}
                value={data}
                multiple
                autoFocus={autoFocus}
                onChange={handleChange}
                emptyItemRender={renderEmptyItem}
                tagItemRender={renderTagItem}
            />
        </SC.TagCascadeSelectWrapper>
    )
}
