import type { AppUser, Field, GroupBy } from '@lighthouse/core'
import { AppUserStatus, matchFieldPI } from '@lighthouse/core'

import type { ANONYMOUS } from '../../constants'
import { PERSON_ID, PERSON_MAP_NAME } from '../../constants'

export const EMPTY_COLUMN_GROUP = 'EMPTY_COLUMN_GROUP'
export const ALL_GROUP = 'ALL_GROUP'
export const personExtraMap: Record<string, string> = {
    ...PERSON_MAP_NAME,
    EMPTY_COLUMN_GROUP: '空',
    ALL_GROUP: '全部'
}
export const CAN_SELECT_OPTIONS = new Set(['select', 'person'])

export type GroupVisibleOption = {
    label: string
    value: string
    visible: boolean
}
export type StoredGroupVisibleOption = Omit<GroupVisibleOption, 'label'>

/**
 * 根据字段类型获取带有label的完整配置
 * @date 7/18/2023 - 2:00:32 PM
 *
 * @param {Field} field
 * @param {StoredGroupVisibleOption[]} config
 * @returns {*}
 */
export function getGroupVisibleWithLabelOptions(
    field: Field,
    config: StoredGroupVisibleOption[],
    { personOptions }: { personOptions?: AppUser[] }
): GroupVisibleOption[] {
    return matchFieldPI(field)(
        {
            person: () =>
                config.map(item => {
                    return {
                        label: [...PERSON_ID, EMPTY_COLUMN_GROUP, ALL_GROUP].includes(item.value)
                            ? personExtraMap[item.value]
                            : personOptions?.find(p => p.userId === item.value)?.username ?? '',
                        value: item.value,
                        visible: item.visible
                    }
                })
        },
        () =>
            config.map(item => ({
                label: [EMPTY_COLUMN_GROUP, ALL_GROUP].includes(item.value) ? personExtraMap[item.value as typeof ANONYMOUS] : item.value,
                value: item.value,
                visible: item.visible
            }))
    )
}

/**
 * 获取对应字段的所有选项配置
 * @date 7/18/2023 - 2:09:31 PM
 *
 * @export
 * @param {Field} field
 * @param {{ personOptions?: AppUser[] }} { personOptions }
 * @returns {*}
 */
export function getGroupAllVisibleOptions(
    field: Field,
    { personOptions }: { personOptions?: AppUser[] },
    isAllGroup?: boolean
): GroupVisibleOption[] {
    const staticEmptyOption = { label: personExtraMap[EMPTY_COLUMN_GROUP], value: EMPTY_COLUMN_GROUP, visible: true }
    const staticAllOptions = isAllGroup ? [{ label: personExtraMap[ALL_GROUP], value: ALL_GROUP, visible: true }] : []
    const staticAnonymous = [...PERSON_ID].map(id => ({ label: personExtraMap[id], value: id, visible: true }))
    //
    return matchFieldPI(field)(
        {
            person: f => [
                ...staticAllOptions,
                ...(personOptions || []).reduce<GroupBy[]>((list, item) => {
                    if (item?.state === AppUserStatus.DEPART) {
                        return list
                    }
                    list.push({ label: item.username, value: item.userId, visible: true })
                    return list
                }, []),
                ...(['updatedBy', 'createdBy'].includes(f.id) ? staticAnonymous : [staticEmptyOption])
            ],
            select: f => [
                ...staticAllOptions,
                ...f.select.options.map(item => ({ label: item.label, value: item.label, visible: true })),
                staticEmptyOption
            ]
        },
        () => []
    )
}

/**
 * 将当前选项配置，与另一个完整的选项配置合并，并过滤过期的选项
 * @date 7/18/2023 - 2:24:42 PM
 *
 * @export
 * @param {GroupVisibleOption[]} storedOptions
 * @param {GroupVisibleOption[]} allOptions
 * @returns {GroupVisibleOption[]}
 */
export function mergeGroupVisibleOptions(storedOptions: GroupVisibleOption[], allOptions: GroupVisibleOption[]): GroupVisibleOption[] {
    if (storedOptions.length === 0) {
        return allOptions
    }

    const mergedOptions: GroupVisibleOption[] = []

    // 过滤当前的配置选项
    for (const option of storedOptions) {
        // 如果在合法的选项列表中有，则存入，否则视为过期的选项
        if (allOptions.some(item => item.value === option.value)) {
            mergedOptions.push({ ...option })
        }
    }

    // 将新的选项合并
    for (const option of allOptions) {
        // 如果已经有该选项，跳过
        if (mergedOptions.some(item => item.value === option.value)) {
            continue
        }
        if (option.value === ALL_GROUP) {
            mergedOptions.unshift({ ...option })
            continue
        }
        mergedOptions.push({ ...option })
    }

    return mergedOptions
}
